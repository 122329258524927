import React, {useEffect,useState} from 'react';
import { ResponseResult, Student, RouteParam, Package } from '../../types';
import {Card, Row, Col, Button, Form, Input, Table, Space, Checkbox} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import {useParams} from 'react-router-dom';

function PackagesAssignedList(){

    const [students, setStudents] = useState<Student[]>([]);
    const [packagePayment, setPackage] = useState<Partial<Package>>({});
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [checkBoxChecked, setCheckBoxChecked] = useState<boolean>(false);
    const {id} = useParams<RouteParam>();

    useEffect(()=>{
        getPackage();
    }, []);

    const getPackage = () => {
        Swal.fire("Paquete", "Realizando petición...");
        Swal.showLoading();
        request('/api/package/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setPackage(result.data);
            }
        })
    }

    useEffect(()=>{
        getAssignedStudents("");
    }, []);

    const getAssignedStudents = (enrollment:string) => {
        const filter: any = {}
        filter.searchAllStatuses = true;
        if(enrollment !== ''){
            filter.searchParam = enrollment;
        }
        request(`/api/student?payment_package=${id}&search=${JSON.stringify(filter)}`,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudents(result.data);
            }
        })
    }

    const removePackage = (student:Student) => {
        Swal.fire({
            title: 'Desasignar paquete',
            html: '¿Estás seguro de desasignar el paquete ' + packagePayment.name + ' al alumno(a) '+ student.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Desasignar paquete", "Realizando petición...");
                Swal.showLoading();
                request("/api/student/assigned-list/unset/"+student._id,{
                    method: 'PUT'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        Swal.fire("Desasignar paquete", "Se ha desasignado el paquete correctamente", "success");
                        getAssignedStudents("");
                    }else{
                        Swal.fire("Desasignar paquete", "Ocurrió un error al desasignar el paquete al alumno", "error");
                    }
                })
            }
        })
    }

    const onChange = (e: any) => {
        const enrollment = e.target.value;
        getAssignedStudents(enrollment)
    }

    const unassignBulkPackage =(e:React.SyntheticEvent)=>{
        e.preventDefault();
        Swal.fire({
            title: 'Asignar paquete',
            html: '¿Estás seguro de asignar el paquete a '+ selectedStudents.length +' alumnos?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Asignar paquete", "Asignando paquete...");
                Swal.showLoading();
                request("/api/student/assigned-list/unset",{
                    method:'PUT',
                    body: JSON.stringify({
                        students: selectedStudents,
                        package: id
                    })
                })
                .then((result:ResponseResult)=>{
                    if(result.data){
                        Swal.fire("Asignar paquete", "Los paquetes han sido asignados correctamente", "success")
                        .then(()=>{
                            getAssignedStudents("");
                        })
                    }else{
                        Swal.fire("Asignar paquete", "Ocurrió un error al asignar el paquete", "error");
                    }
                })
            }
        });
    }

    const selectStudent = (id:string, isChecked?:boolean, flag?: boolean) => {
        let checkbox = (document.getElementById(id) as HTMLInputElement);
        if(isChecked){
            checkbox.checked = flag ? flag : false;
        }
        let data:string[] = [...selectedStudents];
        if(checkbox.checked){
            data.push(id);
        }else{
            data=data.filter((idStudent:string) => {
                return idStudent !== id;
            })
        }
        setSelectedStudents(data);
    }

    const selectMultipleStudent = (id:string, isChecked?:boolean, flag?: boolean) => {
        let checkbox = (document.getElementById(id) as HTMLInputElement);
        if(isChecked){
            checkbox.checked = flag ? flag : false;
        }
        let data:string[] = selectedStudents;
        if(checkbox.checked){
            data.push(id);
        }else{
            data=data.filter((idStudent:string) => {
                return idStudent !== id;
            })
        }
        setSelectedStudents(data);
    }

    const selectAllStudents = () =>{
        if(!checkBoxChecked){
            setCheckBoxChecked(true);
            for(const student of students){
                selectMultipleStudent(student._id, true, true);
            }
        }else{
            setCheckBoxChecked(false);
            setSelectedStudents([]);
            for(const student of students){
                let checkbox = (document.getElementById(student._id) as HTMLInputElement);
                checkbox.checked = false;
            }
        }
    }

    const columns = [
        {
            title: '',
            key: 'cb',
            width: '20px',
            render: (text:string, student:Student, index:number) =>(
                <Space>
                    <Checkbox onChange={()=> selectStudent(student._id)} id={student._id} checked={selectedStudents.includes(student._id)}></Checkbox>
                </Space>
            )
        },
        {
          title: 'Matrícula',
          dataIndex: 'student_id',
          key: 'student_id',
        },
        {
          title: 'Nombre',
          key: 'name',
          render: (text: string, student: Student, index: number) =>(
            <Space>
                {student.name+' '+student.first_lastname+' '+student.second_lastname}
            </Space>
        )
        },
        {
          title: 'Cuatrimestre',
          dataIndex: 'quarter',
          key: 'quarter',
        },
        {
          title: 'Correo',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Teléfono',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Generación',
          dataIndex: 'generation',
          key: 'generation',
        },
        {
            title: 'Opciones',
            key: 'id',
            dataIndex: 'id',
            render: (text:string, student:Student) => (
                <Space>
                    <Button danger onClick={()=>{removePackage(student)}}>
                        Desasignar
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:20,offset:2}}>
                    <Card>
                        <h2>Alumnos asignados al paquete {packagePayment.name}</h2>
                        <Button danger onClick={unassignBulkPackage}>
                            Desasignar paquete
                        </Button>
                        <Form.Item label="Búscar alumno" name="enrollmentSearch">
                            <Input onChange={onChange}/>
                        </Form.Item>
                        <Button type="primary" onClick={selectAllStudents}>
                            Seleccionar todos
                        </Button>
                        <Table  dataSource={students} columns={columns} pagination={{defaultPageSize: 200}} rowKey="id"/>       
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default PackagesAssignedList;