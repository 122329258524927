import React, {useEffect,useRef,useState} from 'react';
import { ResponseResult, Student, RouteParam } from '../../types';
import {Card, Row, Col, Button, Form, Input, message, Select} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function StudentEdit(){

    const [student, setStudent] = useState<Partial<Student>>({});
    const {id} = useParams<RouteParam>();
    const form:any = useRef(null);
    const { Option } = Select;

    useEffect(() => {
        form.current.resetFields()
      }, [student])


    useEffect(()=>{
        getStudent();
    }, []);

    const getStudent = () => {
        request('/api/student/'+id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudent(result.data);
            }else{
                Swal.fire("Error", "No se encontro ningún alumno", "error")
            }
        })
    }

    const submit = (values: Student) => {
        Swal.fire("Editar alumno", "Guardando alumno...");
        Swal.showLoading();  
        request("/api/student/"+id,{
            method:'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/students";
            }else{
                Swal.fire("Editar alumno", "Ocurrió un error al guardar el alumno", "error");
            }
        })
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:10,offset:7}}>
                    <Card>
                        <Form onFinish={submit} initialValues={student} ref={form} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Matrícula del alumno" name="student_id" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la matricula',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nombre del alumno" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Apellido paterno" name="first_lastname" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el apellido paterno',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Apellido materno" name="second_lastname" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el apellido materno',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="CURP" name="curp">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Fecha de ciclo inicial" name="enrollment_date">
                                <Input type="date"/>
                            </Form.Item>
                            <Form.Item label="Email del alumno" name="email" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el correo',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Teléfono del alumno" name="phone">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Estado" name="status_description" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el estado',
                                        },
                                    ]}>
                                <Select className="status_description">
                                    <Option>Seleccionar estatus</Option>
                                    <Option value="En reactivación aspirante" selected={student.concept === 'En reactivación aspirante'}>En reactivación aspirante</Option>
                                    <Option value="En reactivación" selected={student.concept === 'En reactivación'}>En reactivación</Option>
                                    <Option value="Activo" selected={student.concept === 'Activo'}>Activo</Option>
                                    <Option value="Baja temporal" selected={student.concept === 'Baja temporal'}>Baja temporal</Option>
                                    <Option value="Baja definitiva" selected={student.concept === 'Baja definitiva'}>Baja definitiva</Option>
                                    <Option value="Aspirante" selected={student.concept === 'Aspirante'}>Aspirante</Option>
                                    <Option value="Suspensión definitiva" selected={student.concept === 'Suspensión definitiva'}>Suspensión definitiva</Option>
                                    <Option value="Usuario del sistema" selected={student.concept === 'Usuario del sistema'}>Usuario del sistema</Option>
                                    <Option value="Egresado" selected={student.concept === 'Egresado'}>Egresado</Option>
                                    <Option value="Titulado" selected={student.concept === 'Titulado'}>Titulado</Option>
                                    <Option value="Nuevo ingreso" selected={student.concept === 'Nuevo ingreso'}>Nuevo ingreso</Option> 
                                </Select>
                            </Form.Item>
                            <Form.Item label="Día límite de para realizar sus pagos cada mes" name="payment_limit_day">
                                <Select className="limit-day" defaultValue="----">
                                    <Option value="">----</Option>
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                    <Option value="6">6</Option>
                                    <Option value="7">7</Option>
                                    <Option value="8">8</Option>
                                    <Option value="9">9</Option>
                                    <Option value="10">10</Option>
                                    <Option value="11">11</Option>
                                    <Option value="12">12</Option>
                                    <Option value="13">13</Option>
                                    <Option value="14">14</Option>
                                    <Option value="15">15</Option>
                                    <Option value="16">16</Option>
                                    <Option value="17">17</Option>
                                    <Option value="18">18</Option>
                                    <Option value="19">19</Option>
                                    <Option value="20">20</Option>
                                    <Option value="21">21</Option>
                                    <Option value="22">22</Option>
                                    <Option value="23">23</Option>
                                    <Option value="24">24</Option>
                                    <Option value="25">25</Option>
                                    <Option value="26">26</Option>
                                    <Option value="27">27</Option>
                                    <Option value="28">28</Option>
                                    <Option value="29">29</Option>
                                    <Option value="30">30</Option>
                                    <Option value="31">31</Option>
                                </Select>
                            </Form.Item>
                                <Button type="primary" htmlType="submit">Guardar</Button>
                                <Button ghost type="primary" href="/students">Regresar</Button>                                
                            </Form>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default StudentEdit;