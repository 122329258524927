import React from 'react';
import { ResponseResult, Scholarship, RouteParam } from '../../types';
import {Card, Row, Col, Button, Form, Input, Select, Checkbox} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function ScholarshipNew(){

    const { Option } = Select;
    const { id } = useParams<RouteParam>();

    const submit = (values: Scholarship) => {
        Swal.fire("Nueva beca", "Guardando beca...");
        Swal.showLoading();
        const data:any = values;
        data['cycle'] = id;
        request("/api/scholarship",{
            method:'POST',
            body: JSON.stringify(data)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/scholarships";
            }else{
                Swal.fire("Nueva beca", "Ocurrió un error al guardar la beca", "error");
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:8,offset:8}}>
                    <Card>
                        <h2>Nueva beca</h2>
                        <Form onFinish={submit}>
                            <Form.Item label="Nombre de la beca" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre de la beca',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Tipo" name="type" rules={[
                                        {
                                            required: true,
                                            message: 'Selecciona el tipo',
                                        },
                                    ]}>
                                <Select>
                                    <Option>Seleccionar tipo</Option>
                                    <Option value="porcentaje">Porcentaje</Option>
                                    <Option value="cantidad">Cantidad</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Cantidad a descontar" name="amount" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la cantidad a descontar',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item className="mb-3" name="ignore_surcharges" valuePropName="checked">
                                <Checkbox id="ignore_surcharges">
                                    ¿Ignorar recargos?
                                </Checkbox>
                            </Form.Item> 
                            <Button type="primary" htmlType="submit">
                               Guardar
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default ScholarshipNew;