import React, {useEffect,useState,useContext} from 'react';
import { ResponseResult, SchoolCycle } from '../../types';
import { Row, Col, Card, Table, Dropdown, Space, Button, Menu } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { DownOutlined } from '@ant-design/icons';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';

function CyclesList(){

    const [cycles, setCycles] = useState<SchoolCycle[]>([]);
    const { permissions } = useContext(SessionContext);

    useEffect(()=>{
        getCycles();
    }, []);

    const getCycles = () => {
        Swal.fire("Ciclos escolares", "Realizando petición...");
        Swal.showLoading();
        request('/api/cycle',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setCycles(result.data);
            }
        })
    }

    const removeCycle = (cycle:SchoolCycle) => {
        Swal.fire({
            title: 'Eliminar ciclo',
            html: '¿Estás seguro de eliminar el ciclo '+ cycle.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar ciclo escolar", "Realizando petición...");
                Swal.showLoading();
                request("/api/cycle/"+cycle._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getCycles();
                    }else{
                        Swal.fire("Eliminar ciclo", "Ocurrió un error al eliminar el ciclo escolar", "error");
                    }
                })
            }
        })
    }

    const menu = (cycle: SchoolCycle) =>{
        return (
            <Menu>
                {
                    hasPermission(permissions,"Ciclos escolares","edit") && (
                        <Menu.Item>
                            <a href={"/cycles/edit/"+cycle._id}>
                                Editar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions,"Ciclos escolares","remove") && (                        
                        <Menu.Item>
                            <a href="#" onClick={()=>{removeCycle(cycle)}}>
                                Eliminar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions,"Conceptos de ciclos escolares","edit") && (                        
                        <Menu.Item>
                            <a href={"/assigned-concepts/"+cycle._id}>
                                Asignar concepto
                            </a>
                        </Menu.Item>
                    )
                }
                
            </Menu>
        )
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name'
        },
        {
            title: 'Ciclo actual',
            render: (text:string, cycle:SchoolCycle, index:number) => (
                <Space>
                    {cycle.actual_cycle === true ? "Si" : "No"}
                </Space>
            )
        },
        {
            title: 'Opciones',
            render: (text:string, cycle: SchoolCycle, index:number) => (
                <Space>
                    <Dropdown overlay={menu(cycle)} placement="bottomLeft" arrow>
                        <Button type="primary" ghost>
                            Opciones <DownOutlined />
                        </Button>
                    </Dropdown>
                </Space>
            )
        },
    ];

    return (
        <div>
            <Row>
                <Col xs={24} md={{span:18,offset:4}}>
                    <Card>
                        <h2 className="mt-4">Listado de ciclos escolares</h2>
                        {
                            hasPermission(permissions,"Ciclos escolares","create") && (                                
                                <Row>
                                    <Col>
                                        <Button className="mt-2 mb-4" type="primary" href={"/cycles/new"}>Nuevo ciclo escolar</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Table pagination={{defaultPageSize: 50}} dataSource={cycles} columns={columns}/>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default CyclesList;