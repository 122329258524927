import React, {useEffect,useState} from 'react';
import { ResponseResult, Student, SchoolCycle, RouteParam , Concept} from '../../types';
import { Row, Col, Card, Table, Form, Select, Button, Input } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { format, setDate } from 'date-fns';

function StudentCreateConcept(){

    const [student, setStudent] = useState<Partial<Student>>({});
    const [cycles, setCycles] = useState<SchoolCycle[]>([]);
    const [concepts, setConcepts] = useState<Concept[]>([]);
    const [cycleData, setCycleData] = useState<Partial<SchoolCycle>>({});
    const [showTable, setTable] = useState(false);
    const { id } = useParams<RouteParam>();
    const {Option} = Select;

    useEffect(()=>{
        getCycles();
        getStudent();
    }, []);

    const getStudent = () => {
        Swal.showLoading();
        request('/api/student/'+id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudent(result.data);
            }else{
                Swal.fire("Error", "No se encontro ningún alumno", "error")
            }
        })
    }

    const getCycles = () => {
        Swal.fire("Ciclos escolares", "Realizando petición...");
        Swal.showLoading();
        request('/api/cycle',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setCycles(result.data);
            }
        })
    }

    const onSubmit = () => {
        Swal.fire("Creando conceptos", "Realizando petición...");
        Swal.showLoading();
        request("/api/cycle/generateConcepts", {
            method: "POST",
            body: JSON.stringify({student, cycleData, concepts})
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                Swal.fire("Creando conceptos", "La cola de conceptos se ha generado con éxito.",'success')
                .then(()=>{
                    window.location.href = "/cash-payments/"+id
                })
            }else if(result.error.status === 420){
                Swal.fire("Creando conceptos", "El alumno no tiene un paquete asignado", "error");
            }else{
                Swal.fire("Creando conceptos", "Ocurrió un error al generar los conceptos", "error");
            }
        })
    }

    const onSelectedCycle = (e:any) => {
        const data = cycleData;
        data['cycle'] = e;
        setCycleData(data);
    }

    const setPreview = () => {
        if(!cycleData.cycle){
            Swal.fire("Buscar conceptos","Es necesario seleccionar un ciclo.","error")
        }else{
            Swal.fire("Buscar conceptos", "Realizando petición...");
            Swal.showLoading();
            request('/api/concept/list/'+cycleData.cycle,{})
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    setConcepts(result.data);
                    setTable(false);
                    setTable(true);
                }
            })
        }
    }

    const onChangeAmount = (e:React.ChangeEvent<HTMLInputElement>, index: number, originalValue?:number) => {
        let data = [...concepts];
        if(e.target.name === 'amount'){
            const value = Number(e.target.value);
            data[index]['amount'] = value;
            if(value !== originalValue){
                data[index]['changed'] = true;
            }else{
                data[index]['changed'] = false;
            }
        }
        if(e.target.name === 'reason'){
            data[index]['reason'] = e.target.value;
        }
        setConcepts(data);
    }

    const columns = [
        {
            title: 'Tipo',
            dataIndex: 'concept'
        },
        {
            title: 'Concepto',
            dataIndex: 'title'
        },
        {
            title: 'Importe',
            render: (text:string, concept: Concept, index: number) => (
                <div className="text-end">
                    {
                        concept.concept === 'Colegiatura' && (
                            <Form.Item className="mb-3" name="amount" label="$">
                                <Input type="number" name="amount" defaultValue={ student.payment_package?.monthly_amount } onChange={(e:React.ChangeEvent<HTMLInputElement>) =>onChangeAmount(e, index, student.payment_package?.monthly_amount)}/>
                            </Form.Item>
                        )
                    }
                    {
                        concept.concept === 'Inscripción'  && (                                                        
                            <Form.Item className="mb-3" name="amount" label="$">
                                <Input type="text" name="amount" defaultValue={ student.payment_package?.re_enrollment_amount } onChange={(e:React.ChangeEvent<HTMLInputElement>) =>onChangeAmount(e, index, student.payment_package?.re_enrollment_amount)}/>
                            </Form.Item>
                        ) 
                    } 
                    {
                        concept.concept === 'Gastos administrativos'  && (
                            <Form.Item className="mb-3" name="amount" label="$">
                                <Input type="text" name="amount" defaultValue={ student.payment_package?.administrative_expenses } onChange={(e:React.ChangeEvent<HTMLInputElement>) =>onChangeAmount(e, index, student.payment_package?.administrative_expenses)}/>
                            </Form.Item>
                        )
                    }    
                </div>
            )
        },
        {
            title: 'Razón de cambio',
            render: (text:string, concept: Concept, index:number) => (
                <div className="text-end">
                    {
                        concept.changed && (
                            <Form.Item className="mb-3" name="reason">
                                <Input type="text" name="reason" onChange={(e:React.ChangeEvent<HTMLInputElement>) =>onChangeAmount(e, index)}/>
                            </Form.Item>
                        )
                    }
                </div>
            )
        },
        {
            title: 'Fecha de vencimiento',
            render: (text:string, concept: Concept, index: number) => (
                <div className="text-end">
                    { student.payment_limit_day ? format(setDate( new Date(concept.limit_date), student.payment_limit_day ), 'dd/MM/yyyy') : format(new Date(concept.limit_date), 'dd/MM/yyyy')}
                </div>
            )
        }
    ];

return(
    <div>
        <Row>
            <Col xs={12} md={{span:18,offset:3}}>
                <Card>
                    <h2>Crear conceptos a alumno { student.name }</h2>                    
                    <Form onFinish={setPreview}>
                        <Form.Item label="Seleccionar ciclo escolar:" className="mb-3" name="cycle">
                                <Select placeholder="" onChange={onSelectedCycle}>
                                <Option value="null">Seleccionar ciclo</Option>
                                    {
                                    cycles.map((cycle:SchoolCycle, key:number)=>(
                                        <Option key={key} value={cycle._id}>{cycle.name}</Option>
                                    ))   
                                    }
                                </Select>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">Ver preview</Button>
                    </Form>
                </Card>
                {
                    showTable && (
                        <Card className="mt-3">
                            <h3>Conceptos a generar del alumno</h3>                            
                            <p>
                                <b>
                                    Paquete asignado al alumno: { student.payment_package?.name }
                                </b>
                            </p>
                            <Table  pagination={{defaultPageSize: 50}} dataSource={concepts} columns={columns}/>
                            <Button htmlType="submit" type="primary" onClick={onSubmit}>Crear conceptos</Button>
                        </Card>
                    )
                }
            </Col>
        </Row>
    </div>  
    )
}

export default StudentCreateConcept;