
const hasPermission = (permissions:any,module:string, action:string) => {
    let flag = false;
    if(permissions){
        for(const permission of permissions){
            if(permission.module === module && permission.actions.includes(action)){
                flag = true;
            }
        }
    }
    return flag;
}

export default hasPermission;