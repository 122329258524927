import React, {useEffect,useState,useContext} from 'react';
import { ResponseResult, User } from '../../types';
import {Card, Table, Row, Col, Button, Space} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';

function UserList(){

    const [users, setUsers] = useState<User[]>([]);
    const { permissions } = useContext(SessionContext);

    useEffect(()=>{
        getUsers();
    }, []);

    const getUsers = () => {
        Swal.fire("Usuarios", "Realizando petición...");
        Swal.showLoading();
        request('/api/user',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setUsers(result.data);
            }
        })
    }

    const removeUser = (user:User) => {
        Swal.fire({
            title: 'Eliminar usuario',
            html: '¿Estás seguro de eliminar al usuario '+ user.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar usuario", "Realizando petición...");
                Swal.showLoading();
                request("/api/user/"+user._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getUsers();
                    }else{
                        Swal.fire("Eliminar usuario", "Ocurrió un error al eliminar el usuario", "error");
                    }
                })
            }
        })
    }

    const columns = [
        {
          title: 'Nombre',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Correo',
          dataIndex: 'email',
          key: 'email',
        },
        {
            title: 'Opciones',
            key: 'id',
            dataIndex: 'id',
            render: (text:string, record:User) => (
                <Space>
                    {
                        hasPermission(permissions,"Usuarios","edit") && (
                            <Button type="primary" ghost href={"/users/edit/"+record._id}>
                                Editar
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Usuarios","edit") && (                        
                            <Button type="primary" ghost href={"/users/permissions/"+record._id}>
                                Permisos
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Usuarios","remove") && (                            
                            <Button danger onClick={()=> removeUser(record)}>
                                Eliminar
                            </Button>
                        )
                    }
                </Space>
            ),
        },        
    ];

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:12,offset:6}}>
                    <Card>
                        <h3>Listado de usuarios</h3>
                        {
                            hasPermission(permissions,"Usuarios","edit") && (
                                <Row>
                                    <Col>
                                        <Button type="primary" href="/users/new">Nuevo usuario</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Table dataSource={users} columns={columns} rowKey="id"/>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default UserList;