import React, {useEffect,useState} from 'react';
import { ResponseResult, SchoolCycle} from '../../types';
import { Row, Col, Card, Form, Select, Button } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function CreatePayments(){

    const [cycles, setCycles] = useState<SchoolCycle[]>([]);
    const [cycleData, setCycleData] = useState<Partial<SchoolCycle>>({});
    const {Option} = Select;

    useEffect(()=>{
        getCycles();
    }, [])

    const getCycles = () => {
        Swal.fire("Ciclos escolares", "Realizando petición...");
        Swal.showLoading();
        request('/api/cycle',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setCycles(result.data);
            }
        })
    }

    const onSelectedCycle = (e:any) => {
        const data = cycleData;
        data['cycle'] = e;
        setCycleData(data);
    }

    const onsubmit = () => {
        if(!cycleData.cycle){
            Swal.fire("Asignar conceptos","Es necesario seleccionar un ciclo.","error")
        }else{
            const cycle = cycles.filter((item:SchoolCycle)=>{
                return item._id === cycleData.cycle
            });
            Swal.fire({
                title: "Asignar conceptos",
                text: `¿Está seguro de asignar todos los conceptos del ciclo ${cycle[0].name}?`,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                showCancelButton: true,
                showConfirmButton: true
            }).then((result) => {
                if(result.isConfirmed){
                    Swal.fire("Asignar conceptos", "Realizando petición...");
                    Swal.showLoading();
                    request('/api/payment/queue',{
                        method: 'POST',
                        body: JSON.stringify({
                            cycle: cycleData.cycle
                        })
                    })
                    .then((result:ResponseResult)=>{
                        Swal.close();
                        if(result.data){
                            Swal.fire("Asignar conceptos", "El proceso para asignar conceptos a todos los alumnos se ha puesta en cola. Este proceso puede demorar varios minutos.","success");
                        }else{
                            Swal.fire("Asignar conceptos", "Ocurrió un error al crear la cola", "error");
                        }
                    })
                }
            });
        }
    }



return(
    <div>
        <Row>
            <Col xs={12} md={{span:18,offset:3}}>
                <Card>
                    <h2>Crear conceptos a alumnos por ciclo</h2>                    
                    <Form onFinish={onsubmit}>
                        <Form.Item label="Seleccionar ciclo escolar:" className="mb-3" name="cycle">
                                <Select placeholder="" onChange={onSelectedCycle}>
                                <Option value="null">Seleccionar ciclo</Option>
                                    {
                                    cycles.map((cycle:SchoolCycle, key:number)=>(
                                        <Option key={key} value={cycle._id}>{cycle.name}</Option>
                                    ))   
                                    }
                                </Select>
                        </Form.Item>
                        <Button type="primary" htmlType="submit">Asignar conceptos del ciclo</Button>
                    </Form>
                </Card>
            </Col>
        </Row>
    </div>  
    )
}

export default CreatePayments;