import React, {useEffect,useState} from 'react';
import { ResponseResult, RouteParam, SchoolCycle, Student } from '../../types';
import { Row, Col, Button, Modal, Table, Tooltip } from 'antd';
import ConceptNew from '../../components/ConceptNew';
import { useParams } from 'react-router-dom';
import ConceptList from '../../components/ConceptList';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ConceptsAssigned(){

    const [cycleData, setCycleData] = useState<Partial<SchoolCycle>>({});
    const {id} = useParams<RouteParam>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [students, setStudents] = useState<Student[]>([])
    const [showDeleteOtherConcepts, setShowDeleteOtherConcepts] = useState(false);

    useEffect(()=>{
        getCycle();
    }, []);

    const getCycle = () => {
        request("/api/cycle/"+id,{})
        .then((response:ResponseResult)=>{
            if(response.data){
                setCycleData(response.data);
            }else{
                Swal.fire("Conceptos asignados", "Ocurrió un error al cargar la información", "error");
            }
        });
    }

    const toggleConcepts = async () => {
        Swal.fire( cycleData.disabled_status_month ? 'Habilitar colegiaturas' : 'Deshabilitar colegiaturas', 'Cargando...' );
        const result = await request('/api/cycle/toggleConcepts/'+id,{
            method:'PUT',
            body: JSON.stringify({
                status: !cycleData.disabled_status_month
            })
        });
        if(result.data){
            getCycle();
            Swal.fire( cycleData.disabled_status_month ? 'Habilitar colegiaturas' : 'Deshabilitar colegiaturas', 'Se han cambiado los conceptos con éxito.', "success" );
        }else{
            Swal.fire( cycleData.disabled_status_month ? 'Habilitar colegiaturas' : 'Deshabilitar colegiaturas', 'Ocurrió un error al realizar el proceso', "error" );
        }
    }

    const createConcepts = async () => {
        const result = await Swal.fire({
            title: 'Asignar concepto',
            html: 'Se le agregaran los conceptos para este ciclo a los alumnos activos que no los tengan asignados, ¿continuar?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        });
        if(result.isConfirmed){
            Swal.fire("Asignando conceptos", "Realizando petición...");
            Swal.showLoading();
            const assignedresult = await request('/api/cycle/assignConcepts/'+id, {});
            if(assignedresult.data){
                Swal.close();
            }
        }
    }

    const showModal= async ()=>{
        Swal.fire('Por favor espere un momento...' );
        Swal.showLoading();
        const studentsWithUnpaidInsc = await request('/api/payment/studentsInscUnpaid/'+id, {});
        setStudents(studentsWithUnpaidInsc.data.students);
        setIsModalOpen(true);
        Swal.close()
    }

    //This function will delete all college fees related to current cycle id (id)
    const cleanConcepts = async ()=>{
        try{
            Swal.showLoading();
            await request('/api/payment/clearConceptsForStudentsWithUnpaidInscription/'+id, {});
            Swal.fire('Conceptos eliminados con éxito', '', 'success');
            setIsModalOpen(false);
        }catch(err){
            Swal.fire('Hubo un error inesperado, intente mas tarde', '', 'error');
        }
    }

    const columns = [
        {
            title: 'Matrícula',
            dataIndex: 'tuition',
            key: 'tuition',
        },
        {
            title: 'Nombre',
            dataIndex: 'full_name',
            key: 'full_name',
        },
    ];

    const deleteOtherConcepts = async () => {
        try {
            Swal.fire("Eliminar otros conceptos","Cargando...");
            Swal.showLoading();
            await request("/api/payment/deleteOtherConceptsNotPaidByStudents",{
                method: 'POST',
                body: JSON.stringify({
                    cycle: id
                })    
            });
            setShowDeleteOtherConcepts(false);
            getCycle();
            Swal.fire("Eliminar otros conceptos", "Los conceptos han sido eliminados exitosamente", "success");
        } catch (error) {
            Swal.fire("Eliminar otros conceptos", "Ocurrió un error al tratar de eliminar los conceptos, por favor intente más tarde", "error");
        }
    }


    return (
        <div>
            <Row>
                <Col xs={{offset:1,span:22}} md={{span:18,offset:3}}>
                    <h2 className="mb-5">Asignar concepto de pago al ciclo escolar {cycleData.name}</h2>
                    <Row className="mb-5">
                        <Col xs={24} md={8}>
                            <Button type="primary" onClick={toggleConcepts}>
                                { cycleData.disabled_status_month ? 'Habilitar colegiaturas' : 'Deshabilitar colegiaturas' }
                            </Button>
                            <Button type="primary" onClick={createConcepts}>
                                { `Crear Conceptos para Alumnos Activos` }
                            </Button>
                            <Tooltip title={`Este botón borra las colegiaturas de todos los alumnos que aun no pagan su inscripción`}>
                                    <Button type="primary" danger onClick={showModal}>
                                        { `Eliminar colegiaturas` } 
                                    </Button>
                            </Tooltip>
                            <Button type="primary" danger onClick={()=>setShowDeleteOtherConcepts(true)}>
                                Eliminar "otros" conceptos no pagados por el estudiante durante el ciclo
                            </Button>
                        </Col>
                    </Row>
                    <ConceptList cycle={id}></ConceptList>
                    <ConceptNew cycle={id}></ConceptNew>
                </Col>  
            </Row>
            <Modal title="Advertencia" visible={isModalOpen} onOk={cleanConcepts} onCancel={()=>setIsModalOpen(false)}>
                <p>Esta acción eliminará todas las colegiaturas de los siguientes alumnos que no han pagado su inscripción: {students.length}</p>
                <Table columns={columns} dataSource={students} scroll={{x:200, y:200}} pagination={false}/>
                <p>¿Seguro de continuar con este proceso?</p>
            </Modal>
            <Modal
            title='Eliminar otros conceptos'
            visible={showDeleteOtherConcepts}
            onOk={deleteOtherConcepts}
            onCancel={()=>setShowDeleteOtherConcepts(false)}
            >
                <p>Esta acción eliminara todos los "otros" conceptos no pagados por todos los estudiantes y ligados al ciclo.</p>
            </Modal>
        </div>
    )
}

export default ConceptsAssigned;