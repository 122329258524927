import React, {useEffect,useState} from 'react';
import { ResponseResult, User, RouteParam } from '../../types';
import {Card, Row, Col, Checkbox, Button} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function UserEdit(){

    const [userData, setUserData] = useState<Partial<User>>({});
    const {id} = useParams<RouteParam>();
    const permissions = [
        {
            name: 'Usuarios',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Paquetes',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Catálogo de conceptos',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Alumnos',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Pagos',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Cajas',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Ciclos escolares',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Conceptos de ciclos escolares',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Reportes',
            permissions: ["create","read","edit","remove"]
        },
        {
            name: 'Recibos',
            permissions: ["remove"]
        }
    ];

    useEffect(()=>{
        getUser();
    }, []);

    const getUser = () => {
        request("/api/user/"+id,{})
        .then((response:ResponseResult)=>{
            if(response.data){
                setUserData(response.data);
            }else{
                Swal.fire("Editar usuario", "Ocurrió un error al cargar la información", "error");
            }
        });
    }

    const submit = () => {
        Swal.fire("Permisos", "Guardando permisos...");
        Swal.showLoading();
        request("/api/user/"+id,{
            method: 'PUT',
            body: JSON.stringify({
                permissions: userData.permissions
            })
        })
        .then((response:ResponseResult)=>{
            if(response.data){
                Swal.fire("Permisos", "Los permisos del usuario se han actualizado", "success");
            }else{
                Swal.fire("Permisos", "Ocurrió un error al actualizar el usuario", "error");
            }
        });
    }

    const onChange = (e:any, action:string, item:{name:string}) => {
        const data = userData;
        if(!data.permissions){
            data.permissions = [];
        }
        let index = -1;
        for(let i = 0; i < data.permissions.length ; i++){
            console.log(data.permissions[i].module === item.name);
            if( data.permissions[i].module === item.name ){
                index = i;
            }
        }
        if(index > -1){
            if( data.permissions[index].actions.includes(action) ){
                data.permissions[index].actions.splice( data.permissions[index].actions.indexOf(action) ,1);
            }else{
                data.permissions[index].actions.push(action);
            }
        }else{
            data.permissions.push({
                module: item.name,
                actions: [ action ]
            });
        }
        setUserData({...userData, ...data});
    }

    const hasPermission = (module:string, action:string) => {
        let flag = false;
        if(userData.permissions){
            for(const permission of userData.permissions){
                if(permission.module === module && permission.actions.includes(action)){
                    flag = true;
                }
            }
        }
        return flag;
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:16,offset:4}}>
                    <Card>
                        <h3>Permisos del usuario {userData.name}</h3>

                        <Button type={"primary"} onClick={submit} className={"mb-5"}>Guardar</Button>
                        <Row className={"custom-header"}>
                            <Col xs={6} md={8}>
                                <b>Permiso</b>
                            </Col>
                            <Col xs={4} md={4}>
                                <b>Crear</b>
                            </Col>
                            <Col xs={4} md={4}>
                                <b>Leer</b>
                            </Col>
                            <Col xs={4} md={4}>
                                <b>Editar</b>
                            </Col>
                            <Col xs={4} md={4}>
                                <b>Eliminar</b>
                            </Col>
                        </Row>
                        {
                            permissions.map((permission:any, key:number)=>(
                                <Row className={"custom-body"} key={key}>
                                    <Col xs={6} md={8}>
                                        { permission.name }
                                    </Col>
                                    <Col xs={4} md={4}>
                                        {
                                            permission.permissions.includes('create') && (
                                                <Checkbox onChange={(e:any) => onChange(e,"create",permission)} checked={ hasPermission(permission.name, "create") }></Checkbox>
                                            )
                                        }
                                    </Col>
                                    <Col xs={4} md={4}>
                                        {
                                            permission.permissions.includes('read') && (                                                
                                                <Checkbox onChange={(e:any) => onChange(e,"read",permission)} checked={ hasPermission(permission.name, "read") }></Checkbox>
                                            )
                                        }
                                    </Col>
                                    <Col xs={4} md={4}>
                                        {
                                            permission.permissions.includes('edit') && (                                                
                                                <Checkbox onChange={(e:any) => onChange(e,"edit",permission)} checked={ hasPermission(permission.name, "edit") }></Checkbox>
                                            )
                                        }
                                    </Col>
                                    <Col xs={4} md={4}>
                                        {
                                            permission.permissions.includes('remove') && (                                                
                                                <Checkbox onChange={(e:any) => onChange(e,"remove",permission)} checked={ hasPermission(permission.name, "remove") }></Checkbox>
                                            )
                                        }
                                    </Col>
                                </Row>
                            ))
                        }
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default UserEdit;