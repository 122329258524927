import React, { useContext, useEffect, useState } from 'react';
import { ResponseResult, Scholarship, SchoolCycle } from '../../types';
import {Card, Table, Row, Col, Button, Dropdown, Menu, Form, Select} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { DownOutlined } from '@ant-design/icons';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';

function ScholarshipList(){

    const [scholarships, setScholarships] = useState<Scholarship[]>([]);
    const { permissions } = useContext(SessionContext);
    const [cycles, setCycles] = useState<SchoolCycle[]>([]);

    const [selectedCycle, setSelectedCycle] = useState<string>('');
    const {Option} = Select;

    useEffect(()=>{
        getCycles();
    }, []);

    const getScholarships = ( cycle:string ) => {
        Swal.fire("Paquetes", "Realizando petición...");
        Swal.showLoading();
        request('/api/scholarship/list/'+cycle,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setScholarships(result.data);
            }
        })
    }

    const getCycles = () => {
        request('/api/cycle',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setCycles(result.data);
                for(const cycle of result.data){
                    if(cycle.actual_cycle){
                        setSelectedCycle(cycle._id);
                        getScholarships(cycle._id)
                    }
                }
            }
        })
    }

    const onChangeCycle = (e:string) => {
        setSelectedCycle(e);
        getScholarships(e)
    }

    const remove = (item:Scholarship) => {
        Swal.fire({
            title: 'Eliminar beca',
            html: '¿Estás seguro de eliminar la beca '+ item.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar beca", "Realizando petición...");
                Swal.showLoading();
                request("/api/scholarship/"+item._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getScholarships(selectedCycle);
                    }else{
                        Swal.fire("Eliminar beca", "Ocurrió un error al eliminar el beca", "error");
                    }
                })
            }
        })
    }

    const menu = (id:string, scholarship:Scholarship) =>{
        return (
            <Menu>
                {
                    hasPermission(permissions,"Paquetes","edit") && (
                        <Menu.Item>
                            <a href={"/scholarships/edit/"+id}>
                                Editar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions,"Paquetes","edit") && (
                        <React.Fragment>
                            <Menu.Item>
                                <div onClick={()=>{remove(scholarship)}}>
                                    Eliminar Registro
                                </div>
                            </Menu.Item>
                            <Menu.Item>
                                <a href={"/scholarships/assign/"+id}>
                                    Asignar beca
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href={"/scholarships/cancel/"+id}>
                                    Cancelar beca
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href={"/scholarships/delete/"+id}>
                                    Eliminar beca
                                </a>
                            </Menu.Item>
                        </React.Fragment>  
                    )
                }
            </Menu>
        )
    }

    const columns = [];
    columns.push({
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    })
    columns.push({
        title: 'Tipo',
        render: (text: string, record: Scholarship, index: number) => (
            <div>
                {record.type}
            </div>
        )
    })
    columns.push({
        title: 'Cantidad',
        render: (text: string, record: Scholarship, index: number) => (
            <div className="text-end">
                {record.amount}
            </div>
        )
    })
    columns.push({
        title: 'Opciones',
        key: 'id',
        dataIndex: 'id',
        render: (text:string, record:Scholarship) => (
            <div className="text-end">
                    <Dropdown overlay={menu(record._id, record)} placement="bottomLeft" arrow>
                    <Button type="primary" ghost>
                        Opciones <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        ),
    })

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:16,offset:4}}>
                    <Card>
                        <h2 className="mb-4">Listado de becas</h2>
                        <Form.Item label="Selecciona un ciclo" name="type">
                            <Select className="type" onChange={onChangeCycle}>
                                <Option>Selecciona un ciclo</Option>
                                {
                                    cycles.map((cycle:SchoolCycle, key:number)=>(
                                        <Option value={cycle._id}>{cycle.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        {
                            hasPermission(permissions,"Paquetes","create") && (                                
                                <Row>
                                    <Col>
                                        <Button className="mb-6" type="primary" href={"/scholarships/new/"+selectedCycle}>Nueva beca</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Table dataSource={scholarships} columns={columns} rowKey="id"/> 
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default ScholarshipList;