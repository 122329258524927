import React, { useContext } from 'react';
import { ResponseResult, Package } from '../../types';
import {Card, Row, Col, Button, Form, Input, Select} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import ConfigContext from '../../context/Config';


function PackagesNew(){

    const { Option } = Select;
    const { components: { packages: config } } = useContext(ConfigContext);

    const submit = (values: Package) => {
        Swal.fire("Nuevo paquete", "Guardando paquete...");
        Swal.showLoading();
        request("/api/package",{
            method:'POST',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/packages";
            }else{
                Swal.fire("Nuevo paquete", "Ocurrió un error al guardar el paquete", "error");
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:8,offset:8}}>
                    <Card>
                        <h2>Nuevo paquete</h2>
                        <Form onFinish={submit}>
                            <Form.Item label="Nombre del paquete" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre del paquete',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Nivel" name="level" rules={[
                                        {
                                            required: true,
                                            message: 'Selecciona el nivel académico para el paquete',
                                        },
                                    ]}>
                                <Select className="status_description">
                                    <Option>Seleccionar nivel</Option>
                                    <Option value="Licenciatura">Licenciatura</Option>
                                    <Option value="Maestría">Maestría</Option>
                                    <Option value="Propedéutico">Propedéutico</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Colegiatura mensual" name="monthly_amount" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la colegiatura mensual',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            { config.admin_payment_enabled && (
                                <Form.Item label="Cantidad de gastos administrativos" name="administrative_expenses" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la cantidad de gastos administrativos',
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                            )}
                            <Form.Item label="Cantidad de reinscripción" name="re_enrollment_amount" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la cantidad de reinscripción',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                               Guardar
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default PackagesNew;