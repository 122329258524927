import React, {useState} from 'react';
import {Card, Row, Col, Button} from 'antd';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';
import {useDropzone} from 'react-dropzone';
import Swal from 'sweetalert2';

function PaymentsUpload(){

    const [selectedFiles, setFiles]:any = useState([]);
    const [successReferences, setSuccess] = useState<string[]>([]);
    const [failedReferences, setFailed] = useState<string[]>([]);

    const onDrop = (acceptedFiles:any) => {
        let files = selectedFiles;
        files = acceptedFiles;
        setFiles(files);
    };
    
    const {getRootProps, getInputProps } = useDropzone({
        multiple:true, 
        onDrop: onDrop,
        accept: 'text/plain'
    });

    const upload = () => {
        if(selectedFiles.length > 0){
            Swal.fire('Subir pagos','Cargando...');
            Swal.showLoading();
            const data = new FormData();
            for(let file of selectedFiles){
                data.append('files', file);
            }
            request('/api/payment/upload',{
                method: 'POST',
                file:true,
                body: data
            })
            .then((result:ResponseResult)=>{
                Swal.close();                
                if(result.data){
                    Swal.fire('Subir pagos', 'El proceso ha terminado, cierra esta ventana para ver el resumen.', 'success');
                    setSuccess(result.data.success);
                    setFailed(result.data.failed);
                }else{
                    Swal.fire('Subir pagos', 'Ocurrió un error', 'error');
                }
            })
        }
    }

    return (
        <div id="upload-payments">
            <Row>
                <Col xs={24} md={{span:18,offset:3}}>
                    <Card>
                        <h3>Subir pagos realizados</h3>                        
                            <div className='file-drop justify-center mt-5' {...getRootProps()}>
                                <input {...getInputProps({multiple:true})} />
                                <p className="text-center mb-4">Arrastra y suelta tus archivos o presiona aquí para elegirlos.</p> 
                            </div>
                            <p><b>Archivo seleccionado:</b></p>
                            <ul className="mb-5">
                                {
                                    selectedFiles.map((file:any,key:number)=>(
                                        <li key={key}>
                                            { file.name }
                                        </li>
                                    ))
                                }
                            </ul>
                            <Button type="primary" onClick={upload}>Subir archivo</Button>

                            <h3 className="mt-10">Resumen de importación</h3>
                            <Row>
                                <Col xs={24} md={12}>
                                    <p><b>Exitosos</b></p>
                                    {
                                        successReferences?.map((item:string,key:number)=>(
                                            <p key={key}>{item}</p>
                                        ))
                                    }
                                </Col>
                                <Col xs={24} md={12}>
                                    <p><b>Fallidos</b></p>
                                    {
                                        failedReferences?.map((item:string,key:number)=>(
                                            <p key={key}>{item}</p>
                                        ))
                                    }
                                </Col>
                            </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentsUpload;