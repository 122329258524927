import React, {useContext} from 'react';
import { Row, Col, Form, Card, Input, Select, Button, message } from 'antd';
import {request} from '../../services/Request';
import { ResponseResult } from '../../types';
import Swal from 'sweetalert2';
import ConfigContext from '../../context/Config';

type Report = {
    start: Date;
    end: Date;
    type: string;
    [key:string]:any;
}

function ConceptsReport(){

    const {Option} = Select;
    const { components: config } = useContext(ConfigContext);
    const { menu: menuConfig } = useContext(ConfigContext);

    const search = (values: Report) => {
        request("/api/payment/report?type="+values.type+"&start="+values.start+"&end="+values.end,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                Swal.fire("Reporte", "La petición del reporte se ha generado. Este llegara a su correo en unos minutos.", "success");
            }else{
                Swal.fire("Reporte", "Ocurrió un error al generar la petición", "error");
            }
        });
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div className="">
            <Row>
                <Col xs={24} md={{span:20, offset:2}}>
                    <Card title="Reporte de estatus de conceptos de alumnos">
                        <Form onFinish={search} onFinishFailed={onFinishFailed}>
                            <Row className="mt-5 align-items-end">
                                <Col xs={12} md={4}>
                                    <Form.Item label="Desde" name="start" className="mr-6" rules={[
                                        {
                                            required: true,
                                            message: "Selecciona la fecha inicial"
                                        }
                                    ]}>
                                        <Input type="date" name="start"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Form.Item label="Hasta" name="end" className="mr-6" rules={[
                                        {
                                            required: true,
                                            message: "Selecciona la fecha final"
                                        }
                                    ]}>
                                        <Input type="date" name="end"/>
                                    </Form.Item>
                                </Col>
                                    <h4 className="mr-3">Tipo de reporte:</h4>
                                <Col xs={24} md={5}>
                                    <Form.Item className="mr-5" name="type" rules={[
                                        {
                                            required: true,
                                            message: "Selecciona el tipo"
                                        }
                                    ]}>
                                        <Select aria-label="Tipo" className="type" defaultValue="Seleccionar tipo">
                                            <Option>Seleccionar tipo</Option>
                                            {
                                                config.reports.debts === 'all' ? (
                                                    <Option value="debts">Adeudos</Option>
                                                ): (
                                                    <Option value="debts-active">Adeudos</Option>
                                                )
                                            }
                                            <Option value="students">Alumnos</Option>
                                            {
                                                menuConfig.applicants_enabled && (
                                                    <Option value="applicants">Aspirantes</Option>
                                                )
                                            }
                                            <Option value="amount-assignation">Asignación de montos</Option>
                                            <Option value="cancelled-scholarships">Becas con estatus</Option>
                                            <Option value="scholarship-surcharges">Becados con recargos</Option>
                                            <Option value="cash-closing">Corte de ingresos</Option>
                                            <Option value="all-discounts">Descuentos en alumnos</Option>
                                            {
                                                config.reports.monthly === 'separated' ? (
                                                    <Option value="monthly-statement-separated">Estado mensual</Option>
                                                ): (
                                                    <Option value="monthly-statement">Estado mensual</Option>
                                                )
                                            }
                                            {
                                                config.cash_payments.external_email && (
                                                    <Option value="external">Pagos Externos</Option>
                                                )
                                            }
                                            <Option value="inscriptions">Inscripciones del ciclo actual</Option>
                                            <Option value="recursions">Recursamientos sin pagar</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Button htmlType="submit" type="primary">Buscar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ConceptsReport;