import { createContext } from 'react';

export const initialSession = {
  _id: "",
  name: "No name",
  email: "",
  permissions: [],
  error: null,
}

const SessionContext = createContext(initialSession);

export default SessionContext;