import React from 'react';
import { ResponseResult, User } from '../../types';
import {Card, Row, Col, Button, Form, Input, message} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function UserNew(){

    const submit = async (values: User) => {
        Swal.fire("Nuevo usuario", "Guardando usuario...");
        Swal.showLoading();
        const result: ResponseResult = await request("/api/user",{
            method:'POST',
            body: JSON.stringify(values)
        })
        if(result.status === 200){
            window.location.href = "/users";
        } else if (result.status === 402) {
            Swal.fire("Error al crear usuario", "La dirección de correo ya existe", "error");
        } else {
            Swal.fire("Nuevo usuario", "Ocurrió un error al guardar el usuario", "error");
        }
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:8,offset:8}}>
                    <Card title="">
                    <h3>Nuevo usuario</h3>
                        <Form onFinish={submit} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Nombre" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Correo electrónico" name="email" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el correo electrónico',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                               Guardar
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default UserNew;