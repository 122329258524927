import React, { useEffect, useState, useContext } from 'react';
import { ResponseResult, Student } from '../../types';
import {
    Card,
    Table,
    Row,
    Col,
    Button,
    Space,
    Form,
    Input,
    Checkbox,
    Menu,
    Dropdown } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { DownOutlined } from '@ant-design/icons';
import ConfigContext from '../../context/Config';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';

type SearchFilter = {
    searchAllStatuses?: Boolean,
    searchParam?: string,
}

function StudentList(){

    const [students, setStudents] = useState<Student[]>([]);
    const [searchParam, setSearchParam] = useState('')
    const [searchAllStatuses, setSearchAllStatuses] = useState(false)
    const { components: { students: configStudents } } = useContext(ConfigContext);
    const { permissions } = useContext(SessionContext);

    const searchStudents = async (status:boolean) => {
        const filter: SearchFilter = {}
        filter.searchAllStatuses = status;
        if(searchParam){
            filter.searchParam = searchParam;
        }
        const result:ResponseResult = await request(`/api/student?search=${JSON.stringify(filter)}`,{})
        if(result.data){
            const data = result.data.filter((student:Student)=>{
                if( student.external && hasPermission(permissions,"Cajas","create") ){
                    return student;
                }else{
                    return !student.external ? student : null;
                }
            })
            setStudents(data);
        }
    }

    useEffect(()=>{
        searchStudents(searchAllStatuses);
    }, [searchAllStatuses]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchStudents(searchAllStatuses)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
      }, [searchParam, searchAllStatuses])
    const removeStudent = (student:Student) => {
        Swal.fire({
            title: 'Eliminar alumno',
            html: '¿Estás seguro de eliminar al alumno '+ student.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar alumno", "Realizando petición...");
                Swal.showLoading();
                request("/api/student/"+student._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    if(result.status === 401){
                        Swal.fire("Estudiantes", "El estudiante tiene conceptos asignados y no puede ser eliminado", "error");
                    }else{
                        Swal.close();
                        window.location.reload();
                    }
                })
            }else{
                Swal.fire("Estudiantes", "Ocurrio un error al intentar eliminar el estudiante", "error");
            }
        })
    }


    const onChangeStatusFilter = (e: any) => {
        setSearchAllStatuses(e.target.checked);
        searchStudents(e.target.checked)
    }

    const onChangeSearchParam = (e: any) => {
        const input = e.target.value;
        setSearchParam(input)
    }

    const toggle = (student:Student,status:any) => {
        Swal.fire("Estudiantes","Cambiando el estatus");
        Swal.showLoading();
        request('/api/student/toggle/' + student._id,{
            method: 'PUT',
            body: JSON.stringify(status)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                Swal.close();
                searchStudents(searchAllStatuses);
            }else{
                Swal.fire("Estudiantes", "Ocurrió un error al intentar cambiar el estatus del estudiante", "error");
            }
        })
    }

    const impersonate = (student:Student) => {
        Swal.fire("Estudiantes","Impersonando a " + student.student_id + "...");
        Swal.showLoading();
        request('/api/student/impersonate',{
            method: 'POST',
            body: JSON.stringify({
                student_id: student._id
            })
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                Swal.close();
                window.location.href = `${configStudents.url}?studentToken=${result.data.token}`
            }else{
                Swal.fire("Estudiantes", "Ocurrio un error al intentar impersonar al estudiante", "error");
            }
        })
    }

    const menu = (student: Student) =>{
        return (
            <Menu>
                {
                    hasPermission(permissions, "Alumnos", "edit") && (
                        <Menu.Item>
                            <a href={"/students/edit/"+student._id}>
                                Editar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions, "Alumnos", "remove") && (
                        <React.Fragment>
                            {
                                student.status && (
                                    <Menu.Item>
                                        <span onClick={()=>{toggle(student,{status:false})}}>
                                            Desactivar
                                        </span>
                                    </Menu.Item>
                                )
                            }
                            {
                                !student.status && (
                                    <Menu.Item>
                                        <span onClick={()=>{toggle(student,{status:true})}}>
                                            Reactivar
                                        </span>
                                    </Menu.Item>
                                )
                            }
                            <Menu.Item>
                                <span onClick={()=>{removeStudent(student)}}>
                                    Eliminar
                                </span>
                            </Menu.Item>
                        </React.Fragment>
                    )
                }
                {
                    hasPermission(permissions, "Cajas", "read") && (
                        <React.Fragment>
                            <Menu.Item>
                                <a href={"/cash-payments/"+student._id}>
                                    Estado de cuenta
                                </a>
                            </Menu.Item>
                        </React.Fragment>
                    )
                }
                {
                    hasPermission(permissions, "Cajas", "create") && (
                        <React.Fragment>
                            <Menu.Item>
                                <a  href={"/students/set/"+student._id}>
                                    Crear conceptos
                                </a>
                            </Menu.Item>
                        </React.Fragment>
                    )
                }
                <Menu.Item>
                    <span onClick={()=>impersonate(student)}>
                        Impersonar
                    </span>
                </Menu.Item>
            </Menu>
        )
    }

    const columns = [
        {
            title: 'Matrícula',
            dataIndex: 'student_id',
            key: 'student_id'
        },
        {
            title: 'Nombre',
            key: 'name',
            render: (text: string, student: Student, index: number) =>(
                <Space>
                    <a  href={"/cash-payments/"+student._id}>
                        {[
                            student.first_lastname,
                            student.second_lastname,
                            student.name
                            ].join(' ')
                        }
                    </a>
                </Space>
            )
        },
        {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'CURP',
            dataIndex: 'curp',
            key: 'curp'
        },
        {
            title: 'Estatus',
            render: (text:string, student:Student) => (
                <Space>
                    {
                        student.status && (
                            <td className="text-success">
                                Activo
                            </td>
                        )
                    }
                    {
                        !student.status && (
                            <td className="text-danger">
                                Inactivo
                            </td>
                        )
                    }

                </Space>
            ),
        },
        {
            title: 'Opciones',
            key: 'id',
            dataIndex: 'id',
            render: (text:string, student:Student) => (
                <Space>
                     <Dropdown overlay={menu(student)} placement="bottomLeft" arrow>
                        <Button type="primary" ghost>
                            Opciones <DownOutlined />
                        </Button>
                    </Dropdown>
                </Space>
            ),
        }
    ]


    return (
        <div>
            <Row>
                <Col xs={12} md={{span:20,offset:2}}>
                    <Card>
                        <h2>Listado de alumnos</h2>
                        <Form.Item label="Búsqueda" name="searchByEnrollment" >
                            <Input type="text" onChange={onChangeSearchParam}/>
                        </Form.Item>
                        <Checkbox id={`active`} name='active' onChange={onChangeStatusFilter}>Incluir no activos</Checkbox>
                        {
                            ( hasPermission(permissions,"Alumnos","create") && configStudents.new_form ) && (                                
                                <Row>
                                    <Col>
                                        <Button className="mt-2 mb-4" type="primary" href={"/students/new"}>Nuevo alumno</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Table pagination={{defaultPageSize: 50}} dataSource={students} columns={columns} rowKey="id"/>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default StudentList;