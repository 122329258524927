import React, {useEffect,useRef,useState} from 'react';
import { ResponseResult, User, RouteParam } from '../../types';
import {Card, Row, Col, Button, Form, Input, message} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function UserEdit(){

    const [userData, setUserData] = useState<Partial<User>>({});
    const {id} = useParams<RouteParam>();
    const form:any = useRef(null);

    useEffect(() => {
        form.current.resetFields()
      }, [userData])

    useEffect(()=>{
        getUser();
    }, []);

    const getUser = () => {
        request("/api/user/"+id,{})
        .then((response:ResponseResult)=>{
            if(response.data){
                setUserData(response.data);
            }else{
                Swal.fire("Editar usuario", "Ocurrió un error al cargar la información", "error");
            }
        });
    }

    const submit = (values: User) => {
        Swal.fire("Editar usuario", "Guardando usuario...");
        Swal.showLoading();
        request("/api/user/"+id,{
            method:'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/users";
            }else{
                Swal.fire("Editar usuario", "Ocurrió un error al guardar el usuario", "error");
            }
        })
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:8,offset:8}}>
                    <Card>
                    <h3>Editar usuario</h3>
                        <Form onFinish={submit} initialValues={userData} ref={form} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Nombre" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Código para folio en caja" name="folio_code" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa un código para los recibos generados por este usuario',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Correo electrónico" name="email" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el correo electrónico',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                               Guardar
                            </Button>
                        </Form>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default UserEdit;