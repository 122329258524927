import React, {useState} from 'react';
import {Card, Form, Button, Input, Row, Col, message, Divider} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { ResponseResult } from '../../types';
import GoogleLogin from 'react-google-login';

type LoginData = {
    email:string;
    password: string;
    [key:string]:any;
}

function Login(){
    const submit = (values: LoginData) => {
        Swal.fire("Iniciar sesión", "Cargando...");
        Swal.showLoading();
        request("/api/auth/signin",{
            method: 'POST',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                localStorage.token = result.data.token;
                window.location.href="/students";
            }else if(result.error.status == 420){
                Swal.fire("Iniciar sesión", "Datos incorrectos", "error");
            }else{
                Swal.fire("Iniciar sesión", "Ocurrió un error", "error");
            }
        })
    }

    const responseGoogle = (response:any) => {
        if(response && response.profileObj){
            Swal.fire("Cargando","Por favor espere");
            Swal.showLoading();
            request('/api/auth/google/'+response.profileObj.email,{})
            .then((result:ResponseResult)=>{
                if(result.data){
                    localStorage.token = result.data.token;
                    Swal.close();
                    window.location.href="/students";
                }else{
                    Swal.close();
                    Swal.fire("Error", "Datos incorrectos", "error");
                }
            })
        }else{
            Swal.fire("Error", "Ocurrió un error con Google", "error");
        }
    }

    const onFinishFailed = (_errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div id="login">
            <Row>
                <Col xs={24} md={{span:10,offset:7}}>
                    <Card title="Iniciar sesión">
                    <Form onFinish={submit} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Correo electrónico" name="email" rules={[{
                                            required: true,
                                            message: 'Por favor, ingresa tu correo electrónico',
                                        }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Contraseña" name="password" rules={[{
                                            required: true,
                                            message: 'Por favor, ingresa tu contraseña',
                                        }]}>
                                <Input.Password />
                            </Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ingresar
                        </Button>

                        <Divider plain> Ó </Divider>

                        <Row>
                            <Col xs={12} md={{span:8,offset:8}}>                            
                                <GoogleLogin
                                    clientId={process.env.REACT_APP_CLIENT_GOOGLE || ''}
                                    render={renderProps => (
                                        <Button onClick={renderProps.onClick} disabled={renderProps.disabled} type="primary">Inicia sesión con Google</Button>
                                        )}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                />  
                            </Col>
                        </Row>
                    </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Login;