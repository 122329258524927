import React, { useState } from 'react';
import { Button, Form, Card, message, Select, Input, Row, Col } from "antd";
import Swal from 'sweetalert2';
import { ResponseResult, Concept } from '../types';
import { request } from '../services/Request';

interface Props {
    cycle?: string,
}
function ConceptNew(props: Props) {

    const { cycle } = props;
    const { Option } = Select;
    const [ disabledSubmitButton, setDisabledSubmitButton ] = useState(false)

    const onSubmit = (values: Concept) => {
        setDisabledSubmitButton(true)
        const data = values;
        data['cycle'] = cycle;
        request('/api/concept', {
            method: 'POST',
            body: JSON.stringify(data)
        })
            .then((result: ResponseResult) => {
                if (result.data) {
                    window.location.reload();
                } else {
                    Swal.fire("Nuevo concepto", "Ocurrió un error al agregar el concepto");
                }
            }).catch((_error: any) => {
                Swal.fire("Nuevo concepto", "Ocurrió un error al agregar el concepto");
            })
    }

    const onFinishFailed = (_errorInfo: unknown) => {
        message.error('Es necesario completar todos los campos');
    };


    return (
        <div className="new-heading mt-5">
            <Row>
                <Col xs={24} md={{ span: 18, offset: 3 }}>
                    <Card title="Agregar concepto de pago">
                        <Form onFinish={onSubmit} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Concepto:" className="mb-3" name="concept" rules={[
                                {
                                    required: true,
                                    message: 'Selecciona el tipo de concepto',
                                },
                            ]}>
                                <Select className="selectConcept" defaultValue="Seleccionar concepto">
                                    <Option value="null">Seleccionar concepto</Option>
                                    <Option value="Colegiatura">Colegiatura</Option>
                                    <Option value="Gastos administrativos">Gastos administrativos</Option>
                                    <Option value="Inscripción">Inscripción</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Código del concepto:" className="mb-3" name="code" id="conceptCode" rules={[
                                {
                                    required: true,
                                    message: 'Ingresa el código del concepto',
                                },
                            ]}>
                                <Input type="number" placeholder="" />
                            </Form.Item>
                            <Form.Item label="Título del concepto:" className="mb-3" name="title" id="conceptTitle" rules={[
                                {
                                    required: true,
                                    message: 'Ingresa el título del concepto',
                                },
                            ]}>
                                <Input type="text" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                label="Fecha límite de pago:"
                                className="mb-3"
                                name="limit_date"
                                id="limitDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa la fecha límite de pago',
                                    },
                                ]}>
                                <Input type="date" placeholder="" />
                            </Form.Item>
                            <Form.Item
                                label="Recargo"
                                className='mb-3'
                                name={`lateFee`}
                                id="lateFee"
                            >
                                <Input type="number" placeholder="" />
                            </Form.Item>
                            <div className="d-flex">
                                <Button type="primary" disabled={disabledSubmitButton} htmlType="submit">
                                    Agregar concepto
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ConceptNew;