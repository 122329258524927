import { createContext } from 'react';

export const initialConfig = {
  name: "No name",
  components: {
      packages: {
        admin_payment_enabled: true,
      },
      students: {
        url: 'http://localhost/accountstatus',
        new_form: true
      },
      cash_payments: {
        show_amount_to_pay: true,
        career_enabled: false,
        iva_value: 16,
        uma_value: 1,
        account_enabled: false,
        external_email: false,
        conekta_enabled: false,
      },
      reports: {
        debts: 'all',
        monthly: 'simple'
    }
  },
  menu: {
    cycles_add_new_enabled: true,
    students_batch_upload_enabled: true,
    payments_upload_enabled: true,
    enable_concepts_enabled: false,
    applicants_enabled: false
  },
  error: null,
  loading: true,
}

const ConfigContext = createContext(initialConfig);

export default ConfigContext;