import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Card, Select, message, Input } from 'antd';
import { Concept, ResponseResult } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';

function ConceptEdit({data}:{data:Partial<Concept>}){
    const [conceptData, setConceptData] = useState<Partial<Concept>>({});
    const { Option } = Select;
    const form:any = useRef(null);

    useEffect(() => {
        form.current.resetFields()
      }, [conceptData])

    useEffect(()=>{
        request('/api/concept/'+data._id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setConceptData(result.data);
            }else{
                Swal.fire("Concepto", "Ocurrió un error al cargar el concepto", "error");
            }
        })
    },[data]);

    const submit = (values: Concept) => {        
        Swal.fire("Concepto", "Guardando registro...");
        Swal.showLoading();
        request("/api/concept/"+data._id,{
            method: 'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.reload();
            }else if(result.error.status === 420){
                Swal.fire("Concepto", "El concepto ya existe", "error");
            }else{
                Swal.fire("Concepto", "Ocurrió un error al guardar el concepto", "error");
            }
        })
        .catch((error)=>{
            Swal.fire("Concepto", "Ocurrió un error al guardar el concepto", "error");
        })
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div id="concept-edit-form">
            <Card>
                <h3>Editar concepto de pago</h3>
                    <Form onFinish={submit} onFinishFailed={onFinishFailed} initialValues ={{concept: conceptData.concept, code: conceptData.code, title: conceptData.title,limit_date: conceptData.limit_date ? conceptData.limit_date.split('T')[0] : ''}} ref={form}>
                        <Form.Item label="Concepto:" className="mb-3" name="concept" rules={[
                                {
                                    required: true,
                                    message: 'Selecciona el tipo de concepto',
                                },
                            ]}>
                            <Select className="selectConcept" defaultValue="Seleccionar concepto">
                                <Option value="null">Seleccionar concepto</Option>
                                <Option value="Colegiatura">Colegiatura</Option>
                                <Option value="Gastos administrativos">Gastos administrativos</Option>
                                <Option value="Inscripción">Inscripción</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Código del concepto:" className="mb-3" name="code" id="conceptCode" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el código del concepto',
                                        },
                                    ]}>
                                    <Input type="number" placeholder=""/>
                                </Form.Item>
                        <Form.Item label="Título del concepto:" className="mb-3" name="title" id="conceptTitle" rules={[
                                {
                                    required: true,
                                    message: 'Ingresa el título del concepto',
                                },
                            ]}>
                            <Input type="name" placeholder=""/>
                        </Form.Item>
                        <Form.Item
                            label="Fecha límite de pago:"
                            className="mb-3"
                            name="limit_date"
                            id="limitDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa la fecha límite de pago',
                                },
                            ]}>
                            <Input type="date" placeholder=""/>
                        </Form.Item>
                        <Form.Item
                            label="Recargo"
                            className='mb-3'
                            name={`lateFee`}
                            id="lateFee"
                        >
                            <Input type="number" placeholder="" />
                        </Form.Item>
                        <div className="d-flex">
                            <Button type="primary" htmlType="submit">
                                Guardar concepto
                            </Button>
                        </div>
                    </Form>                                
            </Card>
        </div>
    )
}

export default ConceptEdit;
