import React, { useEffect, useState } from 'react';
import { ResponseResult, Scholarship, RouteParam, Student } from '../../types';
import {Card, Row, Col, Button, Table, Form, Input} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

type Rejected = {
    student: string;
    reason: string;
}

function ScholarshipAssigned(){

    const { id } = useParams<RouteParam>();
    const [scholarship, setScholarship] = useState<Partial<Scholarship>>({});
    const [assignedStudents, setAssignedStudents] = useState<Student[]>([]);
    const [students, setStudents] = useState<Rejected[]>([]);
    const { TextArea } = Input;

    useEffect(()=>{
        getScholarship();
    }, []);

    const getScholarship = () => {
        Swal.fire("Asignar beca", "Cargando...");
        Swal.showLoading();
        request("/api/scholarship/"+id,{
            method:'GET',
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setScholarship(result.data);
                getScholarshipStudents();
            }else{
                Swal.fire("Asignar beca", "Ocurrió un error al cargar la beca", "error");
            }
        })
    }

    const getScholarshipStudents = () => {
        Swal.fire("Asignar beca", "Cargando...");
        Swal.showLoading();
        request("/api/scholarship/students/"+id,{
            method:'GET',
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setAssignedStudents(result.data);
            }else{
                Swal.fire("Asignar beca", "Ocurrió un error al cargar los estudiantes asignados", "error");
            }
        })
    }

    const submit = (values:{student_ids:string}) => {
        Swal.fire("Asignar beca", "Asignando beca...");
        Swal.showLoading();
        request("/api/scholarship/assign/"+id,{
            method:'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudents( result.data.rejected );
                Swal.fire("Asignar beca", "Se ha asignado las becas. Los alumnos asignados y no asignados se desplegaran a continuación", "success")
                .then(()=>{
                    getScholarshipStudents();
                })
            }else{
                Swal.fire("Asignar beca", "Ocurrió un error al guardar la beca", "error");
            }
        })
    }

    const reactivate = (student:Student) => {
        Swal.fire({
            title: 'Reactivar beca',
            text: `¿Está seguro de reactivar la beca a ${student.student_id}?`,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            showConfirmButton: true,
            showCancelButton: true
        }).then((result=>{
            if(result.isConfirmed){
                Swal.fire("Reactivar beca", "Cargando...");
                Swal.showLoading();
                request('/api/scholarship/reactivate/'+id,{
                    method: 'PUT',
                    body: JSON.stringify({
                        student: student._id
                    })
                }).then((result:ResponseResult)=>{
                    Swal.close()
                    if(result.data){
                        Swal.fire("Reactivar beca","Beca habilitada","success");
                        getScholarshipStudents();
                    }else{
                        Swal.fire("Reactivar beca", "Ocurrió un error al reactivar la beca", "error");
                    }
                })
            }
        }))
    }

    const columns = [];
    columns.push({
        title: 'Nombre',
        render: (text:string, record:Student) => (
            <React.Fragment>
                {
                    record.name+" "+record.first_lastname+" "+record.second_lastname
                }
            </React.Fragment>
        )
    })
    columns.push({
        title: 'Matrícula',
        dataIndex: 'student_id',
        key: 'student_id'
    })
    columns.push({
        title: 'Estatus',
        render: (text:string, record:Student) => (
            <React.Fragment>
                {
                    record.scholarship_status ? 'Activo' : 'Cancelado'
                }
            </React.Fragment>
        )
    })
    columns.push({
        title: 'Opciones',
        key: 'id',
        dataIndex: 'id',
        render: (text:string, record:Student) => (
            <React.Fragment>
                {
                    !record.scholarship_status && (
                        <Button type="primary" ghost onClick={()=>reactivate( record )}>
                            Reactivar 
                        </Button>
                    )
                }
            </React.Fragment>
        )
    });

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:16,offset:4}}>
                    <Card>
                        <h2>Asignar beca { scholarship.name }</h2>
                        <Row>
                            <Col xs={24} md={24}>
                                <Form onFinish={submit} initialValues={scholarship}>
                                    <Form.Item label="Ingresa las matrículas de los alumnos a asignar (separados por salto de línea)" name="student_ids" rules={[
                                                {
                                                    required: true,
                                                    message: 'Ingresa las matrículas de los alumnos a asignar',
                                                },
                                            ]}>
                                        <TextArea rows={4} />
                                    </Form.Item>

                                    <Button type="primary" htmlType="submit" className="mb-10">Asignar</Button>
                                </Form>
                            </Col>
                            <Col xs={24} md={24}>
                                <p><b>Fallidos:</b></p>
                                {
                                    students.map((student:Rejected,key:number)=>(
                                        <p key={key}><b>Alumno: </b> { student.student }. <b>Razón: </b>{student.reason}</p>
                                    ))
                                }
                            </Col>
                        </Row>

                        <p><b>Asignados:</b></p>
                        <Table dataSource={assignedStudents} columns={columns} rowKey="_id"/>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default ScholarshipAssigned;