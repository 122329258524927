import React, { useEffect} from 'react';
import {Card, Form, Button, Input, Row, Col} from 'antd';
import { useParams } from 'react-router-dom';
import { ResponseResult, RouteParam } from '../../types';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ConfirmPassword(){

    type LoginData = {
        password:string;
        password2:string
    }
    const { token } = useParams<RouteParam>();

    useEffect(()=>{
        Swal.fire("Usuario","Verificando usuario...");
        Swal.showLoading();
        request('/api/auth/me',{
            headers:{
                'Authorization': 'Bearer '+token
            },
            noRedirect:true
        })
        .then((result:ResponseResult)=>{
            Swal.close();
        }).catch((err)=>{
            Swal.fire("Error", "Ocurrió un error al verificar tu usuario", "error");
        })
    },[]);

    const submit = (values: LoginData) => {
        if(values.password !== values.password2){
            Swal.fire("Establecer contraseña","Las contraseñas no coinciden.","error");
        }else{
            request('/api/auth/setpassword',{
                method: 'POST',
                body: JSON.stringify(values),
                headers:{
                    'Authorization': 'Bearer '+token
                },
                noRedirect:true
            })
            .then((result:ResponseResult)=>{
                if(result.data){    
                    Swal.fire('Establecer contraseña', 'Tu contraseña se ha establecido, ahora puedes iniciar sesión en la plataforma.', 'success')
                    .then((result)=>{
                        window.location.href = "/"; 
                    })
                }else if(result.error.status == 420){
                    Swal.fire("Iniciar sesión", "Datos incorrectos");
                }else{
                    Swal.fire("Iniciar sesión", "Ocurrió un error al iniciar sesión");
                }
            }).catch((error)=>{
                Swal.fire("Iniciar sesión", "Ocurrió un error al iniciar sesión");
            })
        }
    }

    return (
        <div id="login-container">
            <Row className="w-100">
                <Col xs={12} md={{span:15,offset:7}} lg={{span:8,offset:8}}>
                    <Card title="Establecer nueva contraseña">
                        <Form onFinish={submit}>
                            <Form.Item label="Ingresar nueva contraseña" name="password">
                                <Input.Password />
                            </Form.Item>
                            <Form.Item label="Repetir contraseña" name="password2">
                                <Input.Password />
                            </Form.Item>
                            <div className="d-flex">
                                <Button type="primary" htmlType="submit">
                                    Guardar
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ConfirmPassword;