import React, { useState, useEffect, useContext } from 'react';
import { Menu } from 'antd';
import '../styles/header.scss';
import { request } from  '../services/Request';
import { ResponseResult } from '../types';
import { UserOutlined,
         PaperClipOutlined,
         DollarOutlined,
         CreditCardOutlined,
         OrderedListOutlined,
        } from '@ant-design/icons';
import ConfigContext from '../context/Config';
import SessionContext from '../context/Session';
import hasPermission from '../services/HasPermission';

const { SubMenu } = Menu;

function Header(){
    const { menu: config } = useContext(ConfigContext);
    const { permissions } = useContext(SessionContext);

    const logout = () => {
        delete localStorage.token;
        window.location.href="/login";
    }

    return (
        <div id="header">
            <Menu mode="horizontal">
                    {
                        localStorage.token && (
                            <React.Fragment>
                                {
                                    hasPermission(permissions,"Usuarios","read") && (
                                        <Menu.Item key="users" icon={<UserOutlined />}>
                                            <a href="/users">Usuarios</a>
                                        </Menu.Item>
                                    )
                                }
                                {
                                    hasPermission(permissions,"Paquetes","read") && (
                                        <SubMenu key="packages" icon={<DollarOutlined />} title="Paquetes y becas">
                                            <Menu.ItemGroup title="">
                                                <Menu.Item key="payment_packages">
                                                    <a href="/packages">Paquetes</a>
                                                </Menu.Item>
                                                <Menu.Item key="scholarships">
                                                    <a href="/scholarships">Becas</a>
                                                </Menu.Item>
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    )
                                }
                                {
                                    hasPermission(permissions,"Catálogo de conceptos","read") && (
                                        <SubMenu key="concepts" icon={<UserOutlined />} title="Conceptos">
                                            <Menu.ItemGroup title="">
                                                <Menu.Item key="concept_list"><a href="/concepts">Listado</a></Menu.Item>
                                                { hasPermission(permissions,"Catálogo de conceptos","create") && (
                                                    <Menu.Item key="subir_conceptos"><a href="/concepts/upload">Subir conceptos</a></Menu.Item>
                                                )}
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    )
                                }
                                {
                                    hasPermission(permissions,"Alumnos","read") && (
                                        <SubMenu key="students" icon={<UserOutlined />} title="Alumnos">
                                            <Menu.ItemGroup title="">
                                                <Menu.Item key="student_list"><a href="/students">Listado</a></Menu.Item>
                                                { config.students_batch_upload_enabled && (
                                                    <Menu.Item key="subir_alumnos"><a href="/students/upload">Subir alumnos</a></Menu.Item>
                                                )}
                                                {
                                                    config.enable_concepts_enabled && (
                                                        <Menu.Item key="habilitar_alumnos"><a href="/students/enable">Habilitar conceptos en lote</a></Menu.Item>
                                                    )
                                                }
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    )
                                }
                                {
                                   config.applicants_enabled && (
                                        <SubMenu key="applicants" icon={<UserOutlined />} title="Aspirantes">
                                            <Menu.ItemGroup title="">
                                                <Menu.Item key="applicants_list"><a href="/applicants">Listado</a></Menu.Item>
                                                <Menu.Item key="applicants_uploads"><a href="/applicants/upload">Subir aspirantes</a></Menu.Item>
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    )
                                }
                                {
                                    hasPermission(permissions,"Pagos","read") && (
                                        <SubMenu key="payments" icon={<CreditCardOutlined />} title="Pagos">
                                            <Menu.ItemGroup title="">
                                                {
                                                    config.payments_upload_enabled ? (
                                                        <React.Fragment>
                                                            <Menu.Item key="upload_payments"><a href="/payments/upload">Importar pagos</a></Menu.Item>
                                                        </React.Fragment>
                                                    ) : (
                                                        <Menu.Item key="import_payments"><a href="/payments/import">Importar pagos</a></Menu.Item>
                                                        )
                                                    }
                                                <Menu.Item key="upload_paid_payments"><a href="/payments/concept/upload">Subir conceptos pagados</a></Menu.Item>
                                                <Menu.Item key="create_payments"><a href="/payments/create">Crear conceptos de pago múltiples</a></Menu.Item>
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    )
                                }
                                {
                                    hasPermission(permissions,"Ciclos escolares","read") && (
                                        <SubMenu key="schoolCycles" icon={<OrderedListOutlined />} title="Ciclos escolares">
                                            <Menu.ItemGroup title="">
                                                <Menu.Item key="cycles_list"><a href="/cycles">Listado</a></Menu.Item>
                                                { config.cycles_add_new_enabled && (
                                                    <Menu.Item key="new_cycle"><a href="/cycles/new">Nuevo ciclo</a></Menu.Item>
                                                )}
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    )
                                }
                                {
                                    hasPermission(permissions,"Reportes","read") && (
                                        <Menu.Item key="reports" icon={<PaperClipOutlined />}>
                                            <a href="/reports">Reportes</a>
                                        </Menu.Item>
                                    )
                                }
                                <Menu.Item key="session" >
                                    <a onClick={logout}>Cerrar sesión</a>
                                </Menu.Item>
                            </React.Fragment>
                        )
                    }
            </Menu>
        </div>
    )
}

export default Header;