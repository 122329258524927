import React from 'react';
import { ResponseResult, SchoolCycle } from '../../types';
import { Row, Col, Card, Form, Input, message, Button, Checkbox } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function CycleNew(){

    const submit = (values: SchoolCycle) => {
        Swal.fire("Nuevo ciclo", "Guardando ciclo...");
        Swal.showLoading();
        request("/api/cycle",{
            method:'POST',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/cycles";
            }else{
                Swal.fire("Nuevo ciclo escolar", "Ocurrió un error al guardar el ciclo escolar", "error");
            }
        })
    }

    const onFinishFailed = (_errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div>
            <Row>
                <Col xs={24} md={{span:18,offset:3}}>
                    <Card>
                        <h2 className="mt-4">Nuevo ciclo escolar</h2>
                        <Form onFinish={submit} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Nombre" className="mb-3" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre',
                                        },
                                    ]}>
                                <Input type="text" name="name" placeholder=""/>
                            </Form.Item>
                            <Form.Item label="Código" className="mb-3" name="code" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el código',
                                        },
                                    ]}>
                                <Input type="text" name="code" placeholder=""/>
                            </Form.Item>
                            <Form.Item label="Fecha inicio" className="mb-3" name="start" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la fecha de inicio',
                                        },
                                    ]}>
                                <Input type="date"/>
                            </Form.Item>
                            <Form.Item label="Fecha término" className="mb-3" name="end" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la fecha de término',
                                        },
                                    ]}>
                                <Input type="date"/>
                            </Form.Item>
                            <Form.Item className="mb-3" name="actual_cycle" valuePropName="checked">
                                <Checkbox id="actualCycle">
                                    Ciclo actual
                                </Checkbox>
                            </Form.Item> 
                            <Button className="mt-4" htmlType="submit" type="primary">Guardar</Button>
                        </Form>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default CycleNew;