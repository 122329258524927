import React, {useEffect,useState,useContext} from 'react';
import { ResponseResult, Concept } from '../../types';
import { Row, Col, Card, Table, Dropdown, Space, Button, Menu } from 'antd';
import { request } from '../../services/Request';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { DownOutlined } from '@ant-design/icons';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';

function AcademicConceptsList(){

    const [concepts, setConcepts] = useState<Concept[]>([]);
    const { permissions } = useContext(SessionContext);

    useEffect(()=>{
        getConcepts();
    }, []);

    const getConcepts = () => {
        Swal.fire("Conceptos", "Realizando petición...");
        Swal.showLoading();
        request('/api/academicconcept',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setConcepts(result.data);
            }
        })
    }

    const removeConcept = (concept:Concept) => {
        Swal.fire({
            title: 'Eliminar concepto',
            html: '¿Estás seguro de eliminar el concepto '+ concept.title +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar concepto", "Realizando petición...");
                Swal.showLoading();
                request("/api/academicconcept/"+concept._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getConcepts();
                    }else{
                        Swal.fire("Eliminar concepto", "Ocurrió un error al eliminar el concepto", "error");
                    }
                })
            }
        })
    }

    const disableConcept = (concept:Concept) => {
        Swal.fire({
            title: 'Desactivar concepto',
            html: '¿Estás seguro de desactivar el concepto '+ concept.title +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Desactivar concepto", "Realizando petición...");
                Swal.showLoading();
                request("/api/academicconcept/"+concept._id,{
                    method: 'PUT',
                    body: JSON.stringify({...concept, status: false})
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getConcepts();
                    }else{
                        Swal.fire("Desactivar concepto", "Ocurrió un error al desactivar el concepto", "error");
                    }
                })
            }
        })
    }

    const menu = (concept: Concept) =>{
        return (
            <Menu>
                {
                    hasPermission(permissions,"Conceptos de ciclos escolares","edit") && (
                        <Menu.Item>
                            <a href={"/concepts/edit/"+concept._id}>
                                Editar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions,"Conceptos de ciclos escolares","remove") && (                        
                        <Menu.Item>
                            <a onClick={()=>{removeConcept(concept)}}>
                                Eliminar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions,"Conceptos de ciclos escolares","remove") && (                        
                        <Menu.Item>
                            <a onClick={()=>{disableConcept(concept)}}>
                                Desactivar
                            </a>
                        </Menu.Item>
                    )
                }
                
            </Menu>
        )
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'title'
        },
        {
            title: 'Código',
            dataIndex: 'code'
        },
        {
            title: 'Opciones',
            render: (text:string, cycle: Concept, index:number) => (
                <Space>
                    <Dropdown overlay={menu(cycle)} placement="bottomLeft" arrow>
                        <Button type="primary" ghost>
                            Opciones <DownOutlined />
                        </Button>
                    </Dropdown>
                </Space>
            )
        },
    ];

    const archiveConcepts = () => {
        Swal.fire({
            title: 'Archivar conceptos',
            html: '¿Estás seguro de archivar todos los conceptos?',
            icon:'question',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:SweetAlertResult)=>{
            if(result.isConfirmed){
                Swal.fire("Archivar conceptos", "Realizando petición...");
                Swal.showLoading();
                request("/api/academicconcept/archive/concepts",{
                    method: 'PUT',
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getConcepts();
                    }else{
                        Swal.fire("Archivar conceptos", "Ocurrió un error al archivar los conceptos", "error");
                    }
                })
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={24} md={{span:18,offset:4}}>
                    <Card>
                        <h2 className="mt-4">Catálogo de conceptos</h2>
                        {
                            hasPermission(permissions,"Conceptos de ciclos escolares","create") && (                                
                                <Row gutter={5}>
                                    <Col>
                                        <Button className="mt-2 mb-4" type="primary" href={"/concepts/new"}>Nuevo concepto</Button>
                                    </Col>
                                    <Col>
                                        <Button danger className="mt-2 mb-4" type='primary' onClick={archiveConcepts} >Archivar conceptos</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Table pagination={{defaultPageSize: 200}} dataSource={concepts} columns={columns} rowKey="_id"/>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default AcademicConceptsList;