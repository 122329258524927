import React, {useEffect,useRef,useState} from 'react';
import { ResponseResult, SchoolCycle, RouteParam } from '../../types';
import { Row, Col, Card, Form, message, Input, Checkbox, Button } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function CycleEdit(){

    const [cycleData, setCycleData] = useState<Partial<SchoolCycle>>({});
    const {id} = useParams<RouteParam>();
    const form:any = useRef(null);

    useEffect( () => {
        getCycle();
    }, []);

    useEffect(() => {
        form.current.resetFields()
    }, [cycleData])

    const getCycle = () => {
        request("/api/cycle/"+id,{})
        .then((response:ResponseResult)=>{
            if(response.data){
                if(response.data.actual_cycle){
                    (document.getElementById('actualCycle') as HTMLInputElement).checked = true;
                }
                setCycleData(response.data);
            }else{
                Swal.fire("Editar ciclo", "Ocurrió un error al cargar la información", "error");
            }
        });
    }

    const submit = (values: SchoolCycle) => {
    Swal.fire("Editar ciclo", "Guardando ciclo...");
        Swal.showLoading();
        request("/api/cycle/"+id,{
            method:'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/cycles";
            }else{
                Swal.fire("Editar ciclo", "Ocurrió un error al guardar el ciclo escolar", "error");
            }
        })
    }

    const onFinishFailed = (_errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:18,offset:3}}>
                    <Card>
                    <h2>Editar ciclo escolar</h2>
                        <Form onFinish={submit} onFinishFailed={onFinishFailed} initialValues={{
                            name: cycleData.name,
                            start: cycleData.start ? cycleData.start.split('T')[0] : '',
                            end: cycleData.end ? cycleData.end.split('T')[0] : '',
                            code: cycleData.code,
                            actual_cycle: cycleData.actual_cycle
                        }} ref={form}>
                            <Form.Item label="Nombre" name="name" className="mb-3">
                                <Input type="text"  placeholder=""/>
                            </Form.Item>
                            <Form.Item label="Código" name="code" className="mb-3">
                                <Input type="text"  placeholder=""/>
                            </Form.Item>
                            <Form.Item label="Fecha inicio" className="mb-3" name="start" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la fecha de inicio',
                                        },
                                    ]}>
                                <Input type="date"/>
                            </Form.Item>
                            <Form.Item label="Fecha término" className="mb-3" name="end" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la fecha de término',
                                        },
                                    ]}>
                                <Input type="date"/>
                            </Form.Item>
                            <Form.Item className="mb-3" name="actual_cycle" valuePropName="checked">
                                <Checkbox id="actualCycle">
                                    Ciclo actual
                                </Checkbox>
                            </Form.Item> 
                            <Button className="mt-3" htmlType="submit" type="primary">Guardar</Button>
                        </Form>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default CycleEdit;