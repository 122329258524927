import React, {useEffect,useState} from 'react';
import { ResponseResult, Student, Package, RouteParam } from '../../types';
import {Card, Row, Col, Button, Form, Input, Table, Space, Checkbox} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import {useParams} from 'react-router-dom';

function PackagesAssign(){

    const [students, setStudents] = useState<Student[]>([]);
    const [packagePayment, setPackage] = useState<Partial<Package>>({});
    const {id} = useParams<RouteParam>();
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [checkBoxChecked, setCheckBoxChecked] = useState<boolean>(false);

    const getPackage = () => {
        request('/api/package/'+id,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setPackage(result.data);
            }
        })
    }

    useEffect(()=>{
        getPackage();
        getStudents('', id);
    }, []);

    const getStudents = (enrollment:string, id:string) => {
        setSelectedStudents([]);
        setCheckBoxChecked(false);
        const filter: any = {}
        filter.searchAllStatuses = true;
        console.log(enrollment);
        if(enrollment !== ''){
            filter.searchParam = enrollment;
        }
        request(`/api/student?package=${id}&search=${JSON.stringify(filter)}`,{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudents(result.data);
                for(const student of result.data){
                    let checkbox = (document.getElementById(student._id) as HTMLInputElement);
                    checkbox.checked = false;
                }
            }
        })
    }

    const onChange = (e: any) => {
        const enrollment = e.target.value;
        getStudents(enrollment, id)
    }

    const selectStudent = (id:string, isChecked?:boolean, flag?: boolean) => {
        let checkbox = (document.getElementById(id) as HTMLInputElement);
        if(isChecked){
            checkbox.checked = flag ? flag : false;
        }
        let data:string[] = [...selectedStudents];
        if(checkbox.checked){
            data.push(id);
        }else{
            data=data.filter((idStudent:string) => {
                return idStudent !== id;
            })
        }
        setSelectedStudents(data);
    }

    const selectMultipleStudent = (id:string, isChecked?:boolean, flag?: boolean) => {
        let checkbox = (document.getElementById(id) as HTMLInputElement);
        if(isChecked){
            checkbox.checked = flag ? flag : false;
        }
        let data:string[] = selectedStudents;
        if(checkbox.checked){
            data.push(id);
        }else{
            data=data.filter((idStudent:string) => {
                return idStudent !== id;
            })
        }
        setSelectedStudents(data);
    }

    const assignPackage =(e:React.SyntheticEvent)=>{
        e.preventDefault();
        Swal.fire({
            title: 'Asignar paquete',
            html: '¿Estás seguro de asignar el paquete a '+ selectedStudents.length +' alumnos?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Asignar paquete", "Asignando paquete...");
                Swal.showLoading();
                request("/api/package/assign",{
                    method:'POST',
                    body: JSON.stringify({
                        students: selectedStudents,
                        package: id
                    })
                })
                .then((result:ResponseResult)=>{
                    if(result.data){
                        Swal.fire("Asignar paquete", "Los paquetes han sido asignados correctamente", "success")
                        .then(()=>{
                            getStudents('', id);
                        })
                    }else{
                        Swal.fire("Asignar paquete", "Ocurrió un error al asignar el paquete", "error");
                    }
                })
            }
        });
    }

    const selectAllStudents = () =>{
        if(!checkBoxChecked){
            setCheckBoxChecked(true);
            for(const student of students){
                selectMultipleStudent(student._id, true, true);
            }
        }else{
            setCheckBoxChecked(false);
            setSelectedStudents([]);
            for(const student of students){
                let checkbox = (document.getElementById(student._id) as HTMLInputElement);
                checkbox.checked = false;
            }
        }
    }

    const columns = [
        {
            title: '',
            key: 'cb',
            width: '20px',
            render: (text:string, student:Student, index:number) =>(
                <Space>
                    <Checkbox onChange={()=> selectStudent(student._id)} id={student._id} checked={selectedStudents.includes(student._id)}></Checkbox>
                </Space>
            )
        },
        {
            title: 'Matrícula',
            dataIndex: 'student_id',
            key: 'student_id'
        },
        {
            title: 'Nombre',
            key: 'name',
            render: (text:string, student: Student, index:number) => (
                <Space>
                    {student.name+' '+student.first_lastname+' '+student.second_lastname}
                </Space>
            )
        }
    ]

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:20,offset:2}}>
                    <Card>
                        <h2>Asignar paquete {packagePayment.name}</h2>
                            <Row className="pb-3 justify-content-center">
                                <Col xs={12} md={4}>
                                    <Button type="primary" onClick={assignPackage}>
                                        Asignar paquete
                                    </Button>
                                </Col>
                            </Row>
                            <Form.Item label="Buscar alumno" name="enrollmentSearch">
                                <Input onChange={onChange}/>
                            </Form.Item>
                            <Row className="pb-3 justify-content-start">
                                <Col xs={24} md={4}>
                                    <Button type="primary" onClick={selectAllStudents}>
                                        Seleccionar todos
                                    </Button>
                                </Col>
                            </Row>
                        <Table className="mt-6" dataSource={students} columns={columns} pagination={{defaultPageSize: 200}}/>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default PackagesAssign;