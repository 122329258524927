import React, {useEffect,useRef,useState} from 'react';
import { ResponseResult, RouteParam, AcademicConcept } from '../../types';
import { Row, Col, Card, Form, message, Input, Checkbox, Button, Alert } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function AcademicConceptEdit(){

    const [conceptData, setConceptData] = useState<Partial<AcademicConcept>>({});
    const {id} = useParams<RouteParam>();
    const form:any = useRef(null);
    const [showManualField, setManualField] = useState(false);

    useEffect( () => {
        getConcept();
      }, []);

      useEffect(() => {
        form.current.resetFields()
      }, [conceptData])

    const getConcept = () => {
        request("/api/academicconcept/"+id,{})
        .then((response:ResponseResult)=>{
            if(response.data){
                if(response.data.actual_cycle){
                    (document.getElementById('actualCycle') as HTMLInputElement).checked = true;
                }
                setManualField(response.data.manual);
                setConceptData(response.data);
            }else{
                Swal.fire("Editar concepto", "Ocurrió un error al cargar la información", "error");
            }
        });
    }

    const submit = (values: AcademicConcept) => {
    Swal.fire("Editar concepto", "Guardando concepto...");
        Swal.showLoading();
        request("/api/academicconcept/"+id,{
            method:'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/concepts";
            }else{
                Swal.fire("Editar concepto", "Ocurrió un error al guardar el concepto", "error");
            }
        })
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    const onChangeManual = (e:any) => {
        setManualField( e.target.checked );
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:18,offset:3}}>
                    <Card>
                    <h2>Editar concepto</h2>
                        <Form onFinish={submit} onFinishFailed={onFinishFailed} initialValues={conceptData} ref={form}>
                            <Form.Item label="Nombre" className="mb-3" name="title" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre',
                                        },
                                    ]}>
                                <Input type="text" name="name" placeholder=""/>
                            </Form.Item>
                            { !showManualField ? (
                                <Form.Item label="Precio" className="mb-3" name="price" rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa el precio',
                                    },
                                ]}>
                                    <Input type="text" name="price" placeholder=""/>
                                </Form.Item>
                            ):(
                                <Form.Item label="Precio" className="mb-3" name="price">
                                      <Alert message="Al poner el concepto como manual, debes elegir el rango de precios (mínimo y máximo)." type="info" showIcon />
                                </Form.Item>
                            )}                    
                            <Form.Item label="Código" className="mb-3" name="code" rules={[
                                    {
                                        required: true,
                                        message: 'Ingresa un código para el concepto'
                                    }
                                ]}>
                                <Input type="text" name="code"/>
                            </Form.Item>
                            <Row  className="mt-8 mb-3">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="student_can_request">
                                        <Checkbox className="mr-10">¿El alumno puede solicitar este concepto?</Checkbox>
                                    </Form.Item>
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-3">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="includes_iva">
                                        <Checkbox className="mr-10">¿Incluye IVA?</Checkbox>
                                    </Form.Item>
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-3">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="is_monthly">
                                        <Checkbox className="mr-10">¿Se cobra mensualmente?</Checkbox>
                                    </Form.Item>
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-3">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="is_variable">
                                        <Checkbox className="mr-10">¿La cantidad puede variar?</Checkbox>
                                    </Form.Item>
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-3">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="subject">
                                        <Checkbox className="mr-10">¿Se relaciona a una materia?</Checkbox>
                                    </Form.Item>
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-3">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="manual">
                                        <Checkbox className="mr-10" onChange={(e:any)=>onChangeManual(e)}>¿El concepto es manual?</Checkbox>
                                    </Form.Item>
                                    {
                                        showManualField && (
                                            <Row className="mt-8 mb-3">
                                                <Col xs={24} md={{span:5}}>
                                                    <Form.Item label="Mínimo" className="mb-3" name="manual_min" rules={[
                                                            {
                                                                required: true,
                                                                message: 'Ingresa un mínimo'
                                                            }
                                                        ]}>
                                                        <Input type="number"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={{span:5,offset:1}}>
                                                    <Form.Item label="Máximo" className="mb-3" name="manual_max" rules={[
                                                            {
                                                                required: true,
                                                                message: 'Ingresa un máximo'
                                                            }
                                                        ]}>
                                                        <Input type="number"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-1">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="umas_payment">
                                        <Checkbox className="mr-10">Cobrar en UMAs</Checkbox> 
                                    </Form.Item>
                                </Col>                               
                            </Row> 
                            <Row  className="mt-2 mb-1">
                                <Col xs={24} md={{span:24}}>
                                    <Form.Item valuePropName="checked" name="is_available_delete">
                                        <Checkbox className="mr-10">¿Este concepto se puede eliminar?</Checkbox> 
                                    </Form.Item>
                                </Col>                               
                            </Row>
                            <Button className="mt-3" htmlType="submit" type="primary">Guardar</Button>
                        </Form>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default AcademicConceptEdit;