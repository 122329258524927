import React, {useRef} from 'react';
import { ResponseResult, Student } from '../../types';
import {Card, Row, Col, Button, Form, Input, message } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ApplicantNew(){
    const form:any = useRef(null);

    const submit = (values: Student) => {
        Swal.fire("Nuevo aspirante", "Guardando aspirante...");
        Swal.showLoading();  
        request("/api/applicant",{
            method:'POST',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/applicants";
            }else{
                Swal.fire("Nuevo aspirante", "Ocurrió un error al guardar el aplicante", "error");
            }
        })
    }

    const onFinishFailed = (_errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:10,offset:7}}>
                    <Card title={"Nuevo aspirante"}>
                        <Form onFinish={submit} ref={form} onFinishFailed={onFinishFailed}>
                            <Form.Item label="Nombre del alumno" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Apellido paterno" name="first_lastname" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el apellido paterno',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Apellido materno" name="second_lastname" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el apellido materno',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="CURP" name="curp" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el CURP',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Email" name="email" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el correo',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Teléfono" name="phone">
                                <Input />
                            </Form.Item>
                            
                            <Button type="primary" htmlType="submit">Guardar</Button>
                            <Button ghost type="primary" href="/applicants">Regresar</Button>                                
                        </Form>
                    </Card>
                </Col>  
            </Row>
        </div>
    )
}

export default ApplicantNew;