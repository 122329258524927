import React, {useEffect,useState, useContext} from 'react';
import { ResponseResult, Concept, Status, RouteParam, Payment, Subject, AcademicConcept, SchoolCycle, Student } from '../../types';
import { Card, Table, Row, Col, Button, Space, Checkbox, Modal, Form, Select, Input, DatePicker } from 'antd';
import { request } from '../../services/Request';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import ConceptNewStudent from '../../components/ConceptNewStudent';
import ReactJson from 'react-json-view';
import {DollarOutlined, DeleteOutlined, EditOutlined, PrinterOutlined, CloseOutlined, EyeOutlined, PercentageOutlined, PlusOutlined, SwapOutlined } from '@ant-design/icons';
import ConfigContext from '../../context/Config';
import commaSeparator from '../../services/CommaSeparator';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';
import moment from 'moment';

type External = {
    external_name: string;
    worker_number: string;
    [key:string]: string;
}

function CashPayments(){

    const [status, setStatus] = useState<Status[]>([]);
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [cycles, setCycles] = useState<SchoolCycle[]>([]);
    const [academicConcepts, setAcademicConcepts] = useState<Concept[]>([]);
    const [previousStatus, setPreviousStatus] = useState<Status[]>([]);
    const [otherPayments, setOtherPayments] = useState<Status[]>([]);
    const [externalData, setExternalData] = useState<Partial<External>>({});
    const [student, setStudent] = useState({
        _id:'',
        name: '',
        first_lastname: '',
        second_lastname: '',
        student_id: '',
        career: '',
        external: false,
        status: false
    });
    const [selectedConcepts, setSelectedConcepts]:any = useState([]);
    const [selectedOtherConcepts, setSelectedOtherConcepts]:any = useState([]);
    const [selectedList, setSelectedList]:any = useState([]);
    const [selectedConcept, setSelectedConcept]= useState<Partial<Status>>({
        payments:[]
    });
    const [payWindow, setPayWindow] = useState(false);
    const [paymentsWindow, setPaymentsWindow] = useState(false);
    const [newConceptStudent, setNewConceptStudent] = useState(false);
    const { id } = useParams<RouteParam>();
    const [amountToPay, setAmountToPay] = useState(0);
    const [payments, setPayments]: any = useState({payments: []});
    const [showDetail, setShowDetail] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showNewPayment, setNewPayment] = useState(false);
    const [showEditPaymentConcept, setShowEditPaymentConcept] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<Partial<Payment>>({
        payment_detail: '',
    });
    const [selectedConceptIds, setSelectedConceptIds] = useState<string[]>([]);
    const [selectedOtherConceptIds, setSelectedOtherConceptIds] = useState<string[]>([]);
    const { Option } = Select;
    const [showChange, setShowChange] = useState(false);
    const [calculateChange, setCalculateChange] = useState(true);
    const [reasonData, setReasonData] = useState({
        amount: 0,
        reason: '',
        type: ''
    });
    const { components: {cash_payments: config}, loading: isConfigLoading} = useContext(ConfigContext);
    const [changeCash, setChangeCash] = useState(0);
    const { permissions, _id: currentUserId } = useContext(SessionContext);
    const [notes, setNotes] = useState('');
    const [selectedCycle, setSelectedCycle] = useState<string>();
    const [updatePayment, setUpdatePayment]:any = useState({});
    const [updateConcept, setUpdateConcept]:any = useState({});
    const [showNewReference, setNewReference] = useState(false);
    const [newReference, setReference] = useState('');
    const [showTransferPayment, setTransferPayment] = useState(false);
    const [showCancelReceipt, setCancelReceipt] = useState(false);
    const [transferData, setTransferData] = useState({
        student: '' ,
        concept: ''
    });
    const [students, setStudents] = useState<Student[]>([]);
    const [studentConcepts, setStudentConcepts] = useState<Status[]>([]);
    const [showDischargeStudent, setShowDischargeStudent] = useState(false);
    const [dateDischarge, setDateDischarge] = useState(new Date())
    const [ disabledPayButton, setDisabledPayButton ] = useState(false)
    const [ isFormSubmited, setIsFormSubmited ] = useState(false)

    const getAcademicConcepts = () => {
        Swal.showLoading();
        request('/api/academicconcept?price=true',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            console.log("uma", config.uma_value);
            if(result.data){
                let concepts = result.data;
                if(config.uma_value > 1){
                    concepts = result.data.map((concept: AcademicConcept) => {
                        if(concept.umas_payment){
                            const currentConcept = {
                                ...concept,
                                manual_min: concept.manual_min ? concept.manual_min * config.uma_value : null,
                                manual_max: concept.manual_max ? concept.manual_max * config.uma_value : null,
                                price: concept.price ? concept.price * config.uma_value : null,
                            }
                            return currentConcept;
                        } else {
                            return concept;
                        }
                    })
                }
                setAcademicConcepts(concepts)
            }
        })
    }

    const getStudent = () => {
        Swal.fire("Cargando", "Cargando la información del alumno...");
        Swal.showLoading();
        request('/api/student/'+id,{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setStudent(result.data);
                getStatus('');
            }else{
                Swal.fire("Error", "No se encontro ningún alumno", "error")
            }
        })
    }

    useEffect(()=>{
        if(currentUserId && !isConfigLoading){
            getStudent();
            getAcademicConcepts();
            getSubjects();
            getCycles();
            document.addEventListener("wheel", function(event){
                const element:any = document.activeElement;
                if(element && element.type === "number"){
                    element.blur();
                }
            });
        }
    }, [isConfigLoading,currentUserId]);

    const getSubjects = () => {
        request('/api/subject',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setSubjects(result.data);
            }
        })
    }

    const getCycles = () => {
        request('/api/cycle',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setCycles(result.data);
                for(const cycle of result.data){
                    if(cycle.actual_cycle){
                        setSelectedCycle(cycle._id);
                    }
                }
            }
        })
    }

    const onChangeCycle = (e:string) => {
        setSelectedCycle(e);
        getStatus(e);
    }

    const getStatus = (cycle:string) => {
        Swal.fire("Cargando", "Cargando la información del alumno...");
        Swal.showLoading();
        request('/api/payment/'+id+( cycle && cycle !== '' ? '?cycle='+cycle : '' ),{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setStatus(result.data.current);
                setPreviousStatus(result.data.previous);
                setOtherPayments(result.data.other);
            }
        })
    }

    const onChangeTransfer = (value:string, field:string) => {
        const data:any = {...transferData};
        data[field] = value;
        if(field === 'search'){
            searchStudent(value);
        }
        if(field === 'cycle'){
            searchStudentConcepts(value);
        }
        setTransferData({...data});
    }

    const searchStudent = async ( value: string ) => {
        const filter = {
            searchParam: value
        };
        const result:ResponseResult = await request(`/api/student?search=${JSON.stringify(filter)}`,{});
        if(result.data){
            setStudents(result.data);
        }
    }

    const searchStudentConcepts = async ( cycle: string ) => {
        Swal.fire("Cargando", "Cargando la información del alumno...");
        console.log('searchStudentConcepts');
        Swal.showLoading();
        const result= await request('/api/payment/'+transferData.student+( cycle && cycle !== '' ? '?cycle='+cycle : '' ),{});
        Swal.close()
        if(result.data){
            setStudentConcepts(result.data.current);
        }else{
            Swal.fire("Transferir pago", "Ocurrió un error al buscar el alumno", "error")
        }
    }

    const confirmTransfer = async () => {
        Swal.fire("Transferir pago", "Trasnfiriendo el pago del alumno...");
        Swal.showLoading();
        const result = await request('/api/payment/transfer',{
            method: 'POST',
            body: JSON.stringify({
                student: transferData.student,
                concept: transferData.concept,
                payment: selectedPayment._id
            })
        });

        Swal.close()
        if(result.data){
            Swal.fire("Tranferir pago", "Se ha movido el pago correctamente", "success");
        }else{
            Swal.fire("Transferir pago", "Ocurrió un error al transferir el pago", "error")
        }
    }

    const onChangeConcepts = (ids:string[], record:any, index:number) => {
        /*setSelectedOtherConcepts([]);
        setSelectedOtherConceptIds([]);*/
        const element = status.filter((item:any)=>{
            return item.concept._id === record.concept._id
        });
        const previous_element = previousStatus.filter((item:any)=>{
            return item.concept._id === record.concept._id
        });
        const selectedIds = [...selectedConceptIds];
        let selected = [...selectedConcepts];
        if( selectedIds.indexOf(record._id) > -1 ){
            selectedIds.splice( selectedIds.indexOf(record._id), 1 );
        }else{
            selectedIds.push( record._id );
        }
        const exists = selected.filter( (item:any) => {
            return item.concept._id == record.concept._id;
        });
        if(exists.length > 0){
            selected = selected.filter( (item:any) => {
                return item.concept._id != record.concept._id;
            });
        }else if(element.length > 0){
            selected.push( element[0] );
        }else{
            selected.push( previous_element[0] );
        }
        setSelectedConceptIds(selectedIds);
        setSelectedConcepts(selected);
    }

    const onChangeOtherConcepts = (e:any, key:number, record: Status)=>{
        const selected:any = selectedOtherConcepts.map((payment: Status)=>{
            return payment._id;
        })
        if(selected.includes(record._id)){
            selected.splice(selected.indexOf(record._id), 1);
        } else {
            selected.push(record._id);
        }
        const selectedItems = otherPayments.filter((payment: Status)=>{
            return selected.includes(payment._id);
        })
        const selectedItemsIds = otherPayments.filter((payment: Status)=>{
            return selected.includes(payment._id);
        }).map((payment:Status)=>{
            return payment._id;
        })
        setSelectedOtherConceptIds(selectedItemsIds);
        setSelectedOtherConcepts(selectedItems);
    }

    const confirmEdit = () => {
        Swal.fire("Editar pago","Guardando cambio...");
        request("/api/payment/payment/"+selectedPayment._id, {
            method: 'PUT',
            body: JSON.stringify(
                updatePayment
            )
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.reload();
            }else{
                Swal.fire("Editar pago","Ocurrió un error al cambiar la información","error")
            }
        })
    }

    const confirmNewPayment = () => {
        Swal.fire("Nuevo pago","Guardando cambio...");
        request("/api/payment/payment", {
            method: 'POST',
            body: JSON.stringify(
                {
                    data: updatePayment,
                    paymentId: selectedConcept._id
                }
            )
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.reload();
            }else{
                Swal.fire("Nuevo pago","Ocurrió un error al cambiar la información","error")
            }
        })
    }

    const confirmNewReference = () => {
        Swal.fire({
            title: 'Transferir recibo de pago',
            text: `¿Está seguro de copiar el recibo ${newReference}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result)=>{
            if(result.isConfirmed){
                Swal.fire("Transferir recibo de pago","Guardando cambio...");
                request("/api/payment/reference", {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            reference: newReference,
                            paymentId: selectedConcept._id,
                        }
                    )
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        window.location.reload();
                    }else{
                        if(result.error.status === 420){
                            Swal.fire("Transferir referencia de pago","No existe el número de recibo ingresado","error")
                        }else{
                            Swal.fire("Transferir referencia de pago","Ocurrió un error al cambiar la información","error")
                        }
                    }
                })
            }
        });
    }

    const confirmEditPaymentConcept = () => {
        Swal.fire("Editar concepto","Guardando cambio...");
        request("/api/payment/"+selectedConcept._id, {
            method: 'PUT',
            body: JSON.stringify(
                updateConcept
            )
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                window.location.reload();
            }else{
                Swal.fire("Editar concepto","Ocurrió un error al cambiar la información","error")
            }
        })
    }

    const deselectAll = (payments: any) => {
        for(let i = 0; i < payments.length; i++){
            const element = document.getElementsByName('concept-'+payments[i]._id) as NodeListOf<HTMLInputElement>;
            if(element && element[1]){
                element[1].checked = false;
            }
        }
    }

    const onChangePayment = (e:any, field?:string) => {        
        const data = updatePayment;
        if(field){
            data[field] = e === 'true';
        }else{
            data[e.target.name] = e.target.value;
        }
        setUpdatePayment({...data});
    }

    const onChangePaymentConcept = (e:any, field?:string) => {
        const data = updateConcept;
        if(field){
            data[field] = e === 'true';
        }else{
            data[e.target.name] = e.target.value;
        }
        setUpdateConcept({...data});
    }

    const onChangePaymentDiscount = (e:any, index:number) => {
        const data = updateConcept;
        data.discounts[index][e.target.name] = e.target.value;        
        setUpdateConcept({...data});
    }

    const onChangePaymentSurcharge = (e:any, index:number) => {
        const data = updateConcept;
        data.surcharges[index][e.target.name] = e.target.value;        
        setUpdateConcept({...data});
    }

    const addSurcharge = () => {
        const data = updateConcept;
        const date = new Date();
        const yersterday = new Date( date.setDate( date.getDate() -1 ) );
        data.surcharges.push({
            surcharge: 0,
            month:0,
            date: yersterday
        });
        setUpdateConcept({...data});
    }

    const removeDiscount = (index:number) => {
        const data = updateConcept;
        data.discounts.splice(index,1);       
        setUpdateConcept({...data});
    }

    const removeSurcharge = (index:number) => {
        const data = updateConcept;
        data.surcharges.splice(index, 1);       
        setUpdateConcept({...data});
    }

    const showConcepts = () => {
        if(selectedConcepts.length > 0 || selectedOtherConcepts.length > 0){
            if(selectedConcepts.length > 6){
                Swal.fire("Pagar", "No se pueden seleccionar más de 6 conceptos para un recibo.", "error")
            }else{
                let concepts:any = [];
                concepts = concepts.concat( selectedConcepts ).concat( selectedOtherConcepts );
                concepts = concepts.sort( (a:any, b: any) => {
                    if( a.limit_date && b.limit_date ){
                        console.log(1);
                        return new Date(a.limit_date).getTime() - new Date(b.limit_date).getTime();
                    }else{
                        console.log(2);
                        return 1;
                    }
                })
                const data = payments.payments;
                let total = 0;
                    for(const payment of concepts){
                        total += payment.total_amount + payment.surcharges_amount- payment.subtotal - payment.discounts_amount;
                    }
                if(config.show_amount_to_pay === true){
                    data.push({ id: Math.random(), type: hasPermission(permissions,"Cajas","create") ? "Efectivo" : config.conekta_enabled ? 'Oxxo' : '', paid_amount: 0 })
                }else{
                    data.push({ id: Math.random(), type: hasPermission(permissions,"Cajas","create") ? "Efectivo" : config.conekta_enabled ? 'Oxxo' : '', paid_amount: Number(total) })
                }
                setPayments({...payments, ...data});
                setSelectedList(concepts);
                setPayWindow(true);
            }
        }else{
            Swal.fire("Pagar", "Es necesario seleccionar al menos un concepto", "error");
        }
    }

    const onChangeCancelReason = (e:any) => {
        let data = selectedPayment;
        data.reason = e.target.value;
        setSelectedPayment(data);
    }

    const payConcepts = () => {
        setDisabledPayButton(true);
        if(student.external && (!externalData.external_name || externalData.external_name === '')){
            Swal.fire("Pagar", "Es requerido nombre para el externo", "error");
        }else if(config.show_amount_to_pay === true){
            let flag = false;
            let inputSum = 0
            for(let i = 0; i < payments.payments.length; i++) {
                const inputAmount = Number(payments.payments[i].paid_amount);
                inputSum = inputSum + inputAmount;
                if(payments.payments[i].type === null || payments.payments[i].type === undefined || payments.payments[i].type === ''){            
                    flag = true;
                }
            }
            if(amountToPay <= 0){
                Swal.fire("Pagar", "Se debe de pagar una cantidad mayor a 0.", "error")
            } else if(flag === true){
                Swal.fire("Pagar", "Debes seleccionar un método de pago por cada cantidad ingresada", "error");
            }else{
                const total = getSumToTal();
                if(inputSum <= total){
                confirmPayment();
                } else {
                    Swal.fire("Pagar", "No se puede realizar un pago mayor al total de los conceptos seleccionados, por favor revisa las cantidades ingresadas e inténtalo de nuevo", "error");
                }
            }
        } else {
            let flag = false;
            for(let i = 0; i < payments.payments.length; i++){
                if(payments.payments[i].type === null || payments.payments[i].type === undefined || payments.payments[i].type === ''){            
                    flag = true;
                }
            }
            if(flag === true){
                Swal.fire("Pagar", "Selecciona un método de pago", "error");
            } else {
                const currentPayments = payments.payments.filter((p:any) => p.amount > 0)
                if(currentPayments){
                    confirmPayment();
                }
            }
        }
    }

    const confirmPayment = () => {
        Swal.fire("Pagar", "Registrando el pago...");
        Swal.showLoading();
        const paymentData = JSON.stringify({
            concepts: selectedList,
            amount: amountToPay,
            student: student._id,
            payment_methods: payments.payments,
            external_data: externalData,
            notes: notes,
        });
        if(!isFormSubmited){
            setIsFormSubmited(true)
            request('/api/payment',{
                method: 'POST',
                body: paymentData
            })
            .then((result:ResponseResult)=>{
                //Swal.hideLoading();
                if(result.data){
                    setSelectedConcepts([])
                    setSelectedOtherConcepts([])
                    setSelectedList([])
                    if(result.data.last_number){
                        Swal.fire({
                            title: "Pagar",
                            html: "Se ha realizado el pago correctamente. Para descargar el recibo presione aquí: <a href='/api/payment/download/"+result.data.last_number+"' target='_blank' className='btn btn-primary'>Descargar</a>", 
                            icon: "success"
                        })
                        .then(()=>{
                            window.location.reload()
                        });
                    }else if(result.data.url){
                        window.open(result.data.url, "_blank");
                        Swal.fire({
                            title: "Pagar",
                            html: "Se ha generado el recibo correctamente. Este se deberá abrir en una pestaña aparte.", 
                            icon: "success"
                        })
                        .then(()=>{
                            window.location.reload()
                        });
                    }else{
                        Swal.fire({
                            title: "Pagar",
                            html: "Se ha generado el link de pago y enviado al correo de la persona ingresada.", 
                            icon: "success"
                        })
                        .then(()=>{
                            window.location.reload()
                        });
                    }
                    // setPayWindow(false);
                }else{
                    Swal.fire("Pagar", "Ocurrió un error al registrar el pago.", "error");
                }
            })
        }
    }

    const getSumToTal = () => {
        let total = 0;
        for(const payment of selectedList){
            total += payment.total_amount + payment.surcharges_amount- payment.subtotal - payment.discounts_amount;
        }
        const iva = hasIVA();
        return total + iva;
    }

    const ownPayments = (id:string) => {
        const allPayments = previousStatus.concat(status).concat(otherPayments);
        const concept = allPayments.filter((item:any)=>{
            return item._id === id
        });
        setSelectedConcept(concept[0]);
        setPaymentsWindow(true);
    }

    const addPaymentMethod = () => {
        const data = payments;
        data['payments'].push({ id: Math.random(), type: '', paid_amount: 0 })
        setPayments({...payments, ...data});
      }

    const onChangeArray = (e:any, index: number) => {
        const data = payments;
        if(e.paid_amount){
            let amount = Number(data['payments'][index]['paid_amount'] = e.paid_amount);
            let total = getSumToTal();
            if(amount < 0){
                (document.getElementById('concept-input'+index) as any).value = "0";
            }else if(amount > total){
                (document.getElementById('concept-input'+index) as any ).value = String(total);
                amount = total;
            }
            setAmountToPay(amount);
        } else if (e.type){
            setCalculateChange(e.type === "Efectivo")
            if(e.type === 'Oxxo'){
                let amount = data['payments'][index]['paid_amount'] ? data['payments'][index]['paid_amount'] : 0;
                data['payments'] = [];
                data['payments'].push({ id: Math.random(), type: 'Oxxo', paid_amount: amount });
                console.log(data);
            }else{
                data['payments'][index]['type'] = e.type;
            }
        }
        setPayments({...payments, ...data})
    }

    const hasIVA = () => {
        let total = 0;
        for(const payment of selectedList){
            if(payment.concept.includes_iva){
                total += (payment.total_amount + payment.surcharges_amount- payment.subtotal - payment.discounts_amount) * (config.iva_value/100);
            }
        }
        return total;
    }


    const closeModal = () => {
        let data = payments;
        data = { payments:[] }
        setPayments({...data})
        setPayWindow(false)
    }


    const showNewConceptStudent = () =>{
        setNewConceptStudent(true);
        setTimeout(()=>{
            const element = document.getElementById("newConceptStudent");
            element?.scrollIntoView({block: "start"}) as unknown as HTMLElement;
            Swal.close();
        },100);

    }

    const inputSubtotal = () => {
        let inputSum = 0
        console.log(payments.payments);
            for(let i = 0; i < payments.payments.length; i++) {
                const inputAmount = Number(payments.payments[i].paid_amount);
                    inputSum = inputSum + inputAmount;
            }
        return inputSum;
    }

    const showDetailPayment = (payment:Payment) => {
        setShowDetail(true);
        setSelectedPayment(payment);
    }

    const editPayment = (payment:Payment) => {
        setShowEdit(true);
        setUpdatePayment({...payment})
        setSelectedPayment(payment);
    }

    const addPayment = () => {        
        setNewPayment(true);
    }

    const editPaymentConcept = (payment:Status) => {
        setShowEditPaymentConcept(true);
        setUpdateConcept({...payment})
        setSelectedConcept(payment);
    }

    const cancelPayment = (payment:Payment) => {
        setSelectedPayment(payment);
        setCancelReceipt(true);
    }

    const confirmCancelPayment = () => {
        if(selectedPayment.reason && selectedPayment.reason !== ''){
            Swal.fire({
                title: 'Cancelar recibo',
                text: `¿Está seguro de cancelar el recibo ${selectedPayment.receipt_number}?`,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No'
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire("Cancelar recibo","Cancelando...");
                    Swal.showLoading();
                    request("/api/payment/cancel",{
                        method: 'POST',
                        body: JSON.stringify({
                            payment: selectedPayment.receipt_code,  
                            reason: selectedPayment.reason                      
                        })
                    }).then((result:ResponseResult)=>{
                        if(result.data){
                            request("/api/payment/print",{
                                method: 'POST',
                                body: JSON.stringify({
                                    payment: selectedPayment.receipt_code,                        
                                })
                            }).then((result:ResponseResult)=>{
                                if(result.data){
                                    Swal.fire("Cancelar recibo", "El recibo ha sido cancelado", "success")
                                    .then(()=>{
                                        window.location.reload();
                                    })
                                }else{
                                    Swal.fire("Reimprimir recibo", "Ocurrió un error al reimprimir el recibo", "error");
                                }
                            });
                        }else{
                            Swal.fire("Cancelar recibo", "Ocurrió un error al cancelar el recibo", "error");
                        }
                    });
                }
            })
        }else{
            Swal.fire('Cancelar recibo','Es necesario escribir una razón.','error');
        }
    }

    const removePayment = (payment:Payment) => {
        Swal.fire({
            title: 'Eliminar pago',
            text: `¿Está seguro de eliminar el pago de $${payment.paid_amount}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar pago","Cancelar pago...");
                Swal.showLoading();
                request("/api/payment/remove",{
                    method: 'POST',
                    body: JSON.stringify({
                        payment: payment._id,                        
                    })
                }).then((result:ResponseResult)=>{
                    if(result.data){
                        Swal.fire("Eliminar pago", "El pago ha sido removido", "success")
                        .then(()=>{
                            window.location.reload();
                        })
                    }else{
                        Swal.fire("Eliminar pago", "Ocurrió un error al eliminar el pago", "error");
                    }
                });
            }
        })
    }

    const removePaymentConcept = (payment:Status) => {
        Swal.fire({
            title: 'Eliminar concepto de pago',
            text: `¿Está seguro de eliminar el concepto de ${payment.concept.title}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar concepto de pago","Cancelando...");
                Swal.showLoading();
                request("/api/payment/"+payment._id,{
                    method: 'DELETE',
                }).then((result:ResponseResult)=>{
                    if(result.data){
                        Swal.fire("Eliminar concepto de pago", "El concepto de pago ha sido eliminado", "success")
                        .then(()=>{
                            window.location.reload();
                        })
                    }else{
                        Swal.fire("Eliminar concepto de pago", "Ocurrió un error al eliminar el concepto de pago", "error");
                    }
                });
            }
        })
    }

    const printPayment = (payment:Payment) => {
        Swal.fire({
            title: 'Reimprimir recibo',
            text: `¿Está seguro de reimprimir el recibo ${payment.receipt_number}? El recibo anterior será sustituido`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result)=>{
            if(result.isConfirmed){
                Swal.fire("Reimprimir recibo","Cancelando...");
                Swal.showLoading();
                request("/api/payment/print",{
                    method: 'POST',
                    body: JSON.stringify({
                        payment: payment.receipt_code,                        
                    })
                }).then((result:ResponseResult)=>{
                    if(result.data){
                        Swal.fire("Reimprimir recibo", "El recibo ha sido reimprimido", "success")
                        .then(()=>{
                        })
                    }else{
                        Swal.fire("Reimprimir recibo", "Ocurrió un error al reimprimir el recibo", "error");
                    }
                });
            }
        })
    }

    const columns:any = [
        {
            title: 'Concepto',
            render: (text:string, record:Status, index:number) => (
                <React.Fragment>
                    { record.subject ? record.concept.title+" - "+record.subject.name  : record.concept.title} { record.quantity && record.quantity > 1 ? "x"+record.quantity : "" }
                </React.Fragment>
            )
        },
        {
            title: 'F.Vence',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    { record.limit_date ? format(new Date(record.limit_date), 'dd/MM/yyyy') : ''}
                </Space>
            )
        },
        {
            title: 'F.Ultimo pago',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                    record.payments.length > 0 && record.payments[ record.payments.length - 1 ].paid_at ? (
                        <span>{format(new Date(record.payments[ record.payments.length - 1 ].paid_at), 'dd/MM/yyyy')}</span>
                    ) : (
                        <span>Pendiente</span>
                    )
                    }
                </Space>
            )
        },
        {
            title: 'Importe',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.total_amount.toFixed(2)) }</div>
                </div>
            )
        },
        {
            title: 'Recargos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.surcharges_amount.toFixed(2))}</div>
                </div>
            )
        },
        {
            title: 'Descuentos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.discounts_amount.toFixed(2))}</div>
                </div>
            )
        },
        {
            title: 'Pagado',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.subtotal.toFixed(2))}</div>
                </div>
            )
        },
        {
            title: 'Total',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(((record.total_amount + record.surcharges_amount) - record.subtotal - record.discounts_amount).toFixed(2)) }</div>
                </div>
            )
        },
        {
            title: 'Pagar',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                        (!record.status_complete_paid && !record.disabled) && (
                            <Checkbox.Group options={[{label: '', value: record._id}]} value={selectedConceptIds} onChange={(e: any )=>onChangeConcepts(e, record, index)} name={"concept-"+record._id}/>
                        )
                    }
                </Space>
            )
        },
        {
            title: 'Registro de pagos',
            render: (text:string, record:Status, index:number) => (
                <React.Fragment>
                    <Space direction="horizontal" size={[2,2]}>
                        <Button type="primary" onClick={()=>{ownPayments(record._id)}} title="Ver pagos"><EyeOutlined></EyeOutlined></Button>
                        {
                            hasPermission(permissions,"Cajas","create") && (
                                <Button type="primary" ghost onClick={() => changePayment(record)} title="Descontar"><PercentageOutlined></PercentageOutlined></Button>
                            )
                        }
                    </Space>
                    <Space direction="horizontal" size={[2,2]} className="mt-2">
                        {
                            hasPermission(permissions,"Cajas","edit") && (
                                <Button type="primary" ghost onClick={() => editPaymentConcept(record)} title="Editar concepto de pago"><EditOutlined></EditOutlined></Button>
                            )
                        }
                        {
                            hasPermission(permissions,"Cajas","remove") && (
                                <Button type="primary" ghost onClick={() => removePaymentConcept(record)} title="Eliminar concepto de pago"><DeleteOutlined></DeleteOutlined></Button>
                            )
                        }
                    </Space>
                </React.Fragment>
            )
        }
    ];

    const columnsOther:any = [
        {
            title: 'Concepto',
            render: (text:string, record:Status, index:number) => (
                <React.Fragment>
                    { record.subject ? record.concept.title+" - "+record.subject.name  : record.concept.title} { record.quantity && record.quantity > 1 ? "x"+record.quantity : "" } { record.concept.is_variable ? <sup title="El monto puede estar sujeto a cambios">(*)</sup> : '' }
                </React.Fragment>
            )
        },
        {
            title: 'F.Vence',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    { record.limit_date ? format(new Date(record.limit_date), 'dd/MM/yyyy') : ''}
                </Space>
            )
        },
        {
            title: 'F.Ultimo pago',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                    record.payments.length > 0 && record.payments[ record.payments.length - 1 ].paid_at ? (
                        <span>{format(new Date(record.payments[ record.payments.length - 1 ].paid_at), 'dd/MM/yyyy')}</span>
                    ) : (
                        <span>Pendiente</span>
                    )
                    }
                </Space>
            )
        },
        {
            title: 'Importe',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.total_amount.toFixed(2)) }</div>
                </div>
            )
        },
        {
            title: 'Recargos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.surcharges_amount.toFixed(2))}</div>
                </div>
            )
        },
        {
            title: 'Descuentos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.discounts_amount.toFixed(2))}</div>
                </div>
            )
        },
        {
            title: 'Pagado',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(record.subtotal.toFixed(2))}</div>
                </div>
            )
        },
        {
            title: 'Total',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    <div className="no-wrap">$ {commaSeparator(((record.total_amount + record.surcharges_amount) - record.subtotal - record.discounts_amount).toFixed(2)) }</div>
                </div>
            )
        },
        {
            title: 'Pagar',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                        (!record.status_complete_paid && !record.disabled) && (
                            <Checkbox.Group options={[{label: '', value: record._id}]} value={selectedOtherConceptIds} onChange={(e: any )=>onChangeOtherConcepts(e, index, record)} name={"concept-"+record._id}/>
                        )
                    }
                </Space>
            )
        },
        {
            title: 'Registro de pagos',
            render: (text:string, record:Status, index:number) => (
                <React.Fragment>
                    <Space direction="horizontal" size={[2,2]}>
                        <Button type="primary" onClick={()=>{ownPayments(record._id)}} title="Ver pagos"><EyeOutlined></EyeOutlined></Button>
                        {
                            hasPermission(permissions,"Cajas","create") && (
                                <Button type="primary" ghost onClick={() => changePayment(record)} title="Descontar"><PercentageOutlined></PercentageOutlined></Button>
                            )
                        }
                    </Space>
                    <Space direction="horizontal" size={[2,2]} className="mt-2">
                        {
                            hasPermission(permissions,"Cajas","edit") && (
                                <Button type="primary" ghost onClick={() => editPaymentConcept(record)} title="Editar concepto de pago"><EditOutlined></EditOutlined></Button>
                            )
                        }
                        {
                            hasPermission(permissions,"Cajas","remove") && (
                                <Button type="primary" ghost onClick={() => removePaymentConcept(record)} title="Eliminar concepto de pago"><DeleteOutlined></DeleteOutlined></Button>
                            )
                        }
                    </Space>
                </React.Fragment>
            )
        }
    ];

    const registeredPaymentsColumns = [
        {
            title: 'Fecha de creación',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {format(new Date(record.created_at), 'dd/MM/yyyy')}
                </Space>
            )
        },
        {
            title: 'Estatus',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.status_paid ? 'Pagado' : !record.status_paid && record.status_reason ? <p className="text-red">Cancelado</p> : 'Por pagar'}
                </Space>
            )
        },
        {
            title: 'Fecha de pago',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.paid_at ? format(new Date(record.paid_at), 'dd/MM/yyyy') : ''}
                </Space>
            )
        },
        {
            title: 'No. recibo',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.receipt_number ? (
                        <a href={'/api/payment/download/'+record.receipt_code} target='_blank' className='btn btn-primary'>{record.receipt_code}</a>
                     ) : ('')}
                </Space>
            )
        },
        {
            title: 'Cantidad abonada',
            render: (text:string, record:Payment, index:number) => (
                <div className="text-end no-wrap">
                    $ { record.iva_amount ? commaSeparator((record.iva_amount + record.paid_amount).toFixed(2)) : commaSeparator(record.paid_amount.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Tipo de pago',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.type}
                </Space>
            )
        },
        {
            title: 'Detalle',
            render: (text:string, record:Payment, index:number) => (
                <Space direction={'horizontal'}>
                    {
                        record.payment_detail && (
                            <Button onClick={() => showDetailPayment(record)} type="primary" ghost>
                                Detalle
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Cajas","edit") && (
                            <Button onClick={() => editPayment(record)} ghost type="link" title="Editar pago">
                                <EditOutlined></EditOutlined>
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Cajas","create") && record.receipt && record.status_paid && (
                            <Button onClick={() => cancelPayment(record)} ghost type="link" title="Cancelar pago">
                                <DeleteOutlined></DeleteOutlined>
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Cajas","remove") && (
                            <Button onClick={() => removePayment(record)} ghost type="link" title="Eliminar pago">
                                <CloseOutlined></CloseOutlined>
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Cajas","create") && record.receipt && record.status_paid && (
                            <Button onClick={() => printPayment(record)} ghost type="link" title="Reimprimir pago">
                                <PrinterOutlined></PrinterOutlined>
                            </Button>
                        )
                    }
                    {
                        hasPermission(permissions,"Cajas","create") && config.external_email && (
                            <Button type="link" title="Transferir pago a otro concepto" onClick={() => transferPayment(record)}>
                                <SwapOutlined></SwapOutlined>
                            </Button>
                        )
                    }
                </Space>
            )
        }
    ];

    const checkPriceVariable = () => {
        let flag = false;
        for(const payment of otherPayments){
            if(payment.concept.is_variable){
                flag = true;
            }
        }
        return flag;
    }

    const changePayment = (payment: Status) => {
        setSelectedConcept(payment);
        setShowChange(true);
    }

    const checkPaymentMethod = () => {
        let flag = true;
        for(const method of payments.payments){
            if( method.type === 'Oxxo'){
                flag = false;
            }
        }
        return flag;
    }

    const onChangeReason = (e:any) => {
        const data:any = reasonData;
        if(e.target){
            data[e.target.name] = e.target.value;
        }else{
            data["type"] = e;
        }
        setReasonData({...data});
    }

    const onChangeExternal = ( e:React.ChangeEvent<HTMLInputElement> ) => {
        const data = externalData;
        data[e.target.name] = e.target.value;
        setExternalData({...data});
    }

    const confirmChange = () => {
        if(reasonData.amount === 0 || reasonData.type === '' || reasonData.reason === ''){
            Swal.fire("Descontar concepto","Es necesario completar todos los campos","error");
        }else{
            Swal.fire({
                title:"Descontar concepto",
                text: '¿Estás seguro de cambiar el concepto?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: "Sí",
                cancelButtonText: "No"
            }).then((result)=>{
                if(result.isConfirmed){
                    Swal.fire("Cambiar concepto", "Realizando petición...");
                    Swal.showLoading();
                    request("/api/concept/change/"+selectedConcept._id,{
                        method: 'PUT',
                        body: JSON.stringify({
                            amount: Number(reasonData.amount),
                            reason: reasonData.reason,
                            type: reasonData.type
                        })
                    })
                    .then((result:ResponseResult)=>{
                        if(result.data){
                            Swal.fire("Descontar concepto", "El concepto ha sido cambiado.", "success")
                            .then(()=>{
                                window.location.reload();
                            })
                        }else{
                            Swal.fire("Descontar concepto", "El concepto no se pudo cambiar.", "error")
                        }
                    })
                }
            });
        }
    }

    const onChangeCash = (e:any) => {
        const data = payments;
        let cash_total = 0;
        let total_efective = 0;
        for(const payment of data.payments){
            cash_total += payment.paid_amount;
            total_efective++;
        }
        const cash_given = e.target.value !== '' ? Number(e.target.value) : 0;
        setChangeCash( total_efective === data.payments.length ? cash_given - getSumToTal() : cash_given - cash_total );
    }

    const onChangeNote =  (e:any) => {
        setNotes(e.target.value)
    }

    const hasScholarship = () => {
        let flag = false;
        let disabled = false;
        let scholarship = '';
        for(const concept of status){
            if(concept.discounts){
                for(const discount of concept.discounts){
                    if( discount.scholarship && discount.status ){
                        flag = true;
                        scholarship = discount.reason;
                    }
                    if( discount.scholarship && !discount.status ){
                        disabled = true;
                    }
                }
            }
        }
        return flag ? <p><b>Beca: </b>{scholarship} { disabled ? '(Cancelada)' : '' }</p> : '';
    }

    const addReference = () => {
        setNewReference(true);
    }

    const transferPayment = (payment: Payment) => {
        setSelectedPayment(payment)
        setTransferPayment(true);
    }
  
    const downloadAccount = async () => {
        try{
            Swal.fire("Estado de cuenta","Generando...");
            Swal.showLoading();
            const results = await request("/api/payment/account",{
                method: 'POST',
                body: JSON.stringify({
                    student_id: student._id,
                    cycle: selectedCycle
                })    
            });
            Swal.close();
            if(results.data){
                window.open(results.data.url, '_blank');
            }else{
                if(results.error.status === 420){
                    Swal.fire("Estado de cuenta", "El alumno no tiene conceptos pendientes", "error");
                }else{
                    Swal.fire("Estado de cuenta", "Ocurrió un error al descargar el estado de cuenta", "error");
                }
            }
        }catch(e:unknown){
            Swal.fire("Estado de cuenta", "Ocurrió un error al descargar el estado de cuenta", "error");
        }
    }

    const dischargeStudent = async () => {
        try {
            Swal.fire("Dando de baja al estudiante","Cargando...");
            Swal.showLoading();
            await request("/api/student/discharge-student",{
                method: 'POST',
                body: JSON.stringify({
                    student_id: student._id,
                    date_discharge: dateDischarge
                })    
            });
            setShowDischargeStudent(false)
            Swal.fire("Exito","El estudiante ha sido dado de baja con exito", "success")
            .then(_=>window.location.reload());
        } catch (error) {
            Swal.fire("Eliminar otros conceptos", "Ocurrió un error al tratar de eliminar los conceptos, por favor intente más tarde", "error");
        }
    }

    return (
        <div>
            <Row>
                <Col xs={24} md={{span:20,offset:2}}>
                    <Card>
                        <h1>Estado de cuenta</h1>
                        <p><b>Nombre: </b>{student.name+" "+student.first_lastname+" "+student.second_lastname}</p>
                        <p><b>Matrícula: </b>{student.student_id}</p>
                        {
                            config.career_enabled && (
                                <p><b>Carrera: </b>{student.career}</p>
                            )
                        }
                        {
                            hasScholarship()
                        }
                        <Form.Item label="Selecciona un ciclo" name="type">
                            <Select className="type" onChange={onChangeCycle}>
                                <Option>Selecciona un ciclo</Option>
                                {
                                    cycles.map((cycle:SchoolCycle, key:number)=>(
                                        <Option value={cycle._id}>{cycle.name}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        {
                            hasPermission(permissions,"Cajas","create") && (
                                <Row gutter={16}>
                                    <Col md={{span:4}}>
                                        <Button type="primary" ghost onClick={() => { downloadAccount() }}>
                                            Estado de cuenta
                                        </Button>
                                    </Col>                                        
                                     <Col md={{span:4}}>
                                        <Button type="primary" ghost href={"/students/set/"+student._id}>
                                            Crear conceptos
                                        </Button>
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            previousStatus.length > 0 && (
                                <React.Fragment>
                                    <h3>Conceptos pasados</h3>
                                    <Table pagination={false} columns={columns} dataSource={previousStatus} rowKey="_id"/>
                                </React.Fragment>
                            )
                        }
                    </Card>
                    {
                        status.length > 0 && (
                            <Card>
                                <h3>Conceptos en curso</h3>
                                <Table pagination={false} columns={columns} dataSource={status} rowKey="_id"/>
                            </Card>
                        )
                    }
                    {
                        otherPayments.length > 0 && (
                            <Card>
                                <h3>Otros conceptos</h3>
                                <Table pagination={false} columns={columnsOther} dataSource={otherPayments} rowKey="_id"/>
                                {
                                    checkPriceVariable() && (
                                        <p className="mt-10">(*) El monto puede estar sujeto a cambios</p>
                                    )
                                }
                            </Card>
                        )
                    }
                            <Space className="mt-5">
                                <Button type="primary" ghost icon={<DollarOutlined />} onClick={()=>{showConcepts()}} style={{background: 'white'}}>
                                    Pagar seleccionados
                                </Button>
                                {
                                    hasPermission(permissions,"Cajas","create") && (
                                        <>
                                            <Button type="primary" onClick={showNewConceptStudent} className="m-1">
                                                Agregar concepto al estudiante
                                            </Button>
                                            <Button type="primary" ghost href={"/students/set/"+student._id} style={{background: 'white'}}>
                                                Crear conceptos
                                            </Button>
                                            {
                                                hasPermission(permissions,"Alumnos","edit") && (
                                                    <Button type="primary" danger onClick={()=>setShowDischargeStudent(true)}>
                                                        Dar de baja al estudiante
                                                    </Button>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Space>
                    {
                        (newConceptStudent) && (
                            <div className="mt-5 mb-10" id="newConceptStudent">
                                <Row>
                                    <Col xs={12} md={{span:16,offset:4}}>
                                        <ConceptNewStudent cycleId={selectedCycle || ""} IDstudent={student._id} academicConcepts={academicConcepts} subjects={subjects}></ConceptNewStudent>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Col>
            </Row>

            <Modal
            title='Pagar conceptos'
            visible={payWindow}
            onOk={()=>{closeModal()}}
            width={"80%"}
            onCancel={()=>{closeModal()}}
            footer={[
                <Button key="back" danger ghost onClick={closeModal}>
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" disabled={disabledPayButton}  ghost onClick={payConcepts}>
                  Pagar Seleccionados
                </Button>
              ]}
            >
                <Row className="custom-header">
                    <Col xs={24} md={12}>
                        Concepto
                    </Col>
                    <Col xs={12} md={6}>
                        Vence
                    </Col>
                    <Col xs={12} md={6}>
                        Debe
                    </Col>
                </Row>
                {
                    selectedList.map((concept:Status, key:number)=>(
                        <Row className="custom-body">
                            <Col xs={24} md={12}>
                                    { concept.subject ? concept.concept.title+" - "+concept.subject.name  : concept.concept.title} { concept.quantity && concept.quantity > 1 ? "x"+concept.quantity : "" } { concept.concept.is_variable ? <sup title="El monto puede estar sujeto a cambios">(*)</sup> : '' }
                            </Col>
                            <Col xs={12} md={6}>
                                    { concept.limit_date ? format(new Date(concept.limit_date), 'dd/MM/yyyy') : ''}
                            </Col>
                            <Col xs={12} md={6} className="text-end">
                                $ {commaSeparator((concept.total_amount + concept.surcharges_amount - concept.subtotal - concept.discounts_amount).toFixed(2))}
                            </Col>
                        </Row>
                    ))
                }
                {
                    hasIVA() > 0 && (
                        <Row className="custom-footer">
                            <Col xs={12} md={12}>
                                IVA
                            </Col>
                            <Col xs={12} md={12} className="text-end">
                                $ {commaSeparator(hasIVA().toFixed(2))}
                            </Col>
                        </Row>
                    )
                }
                <Row className="custom-footer">
                    <Col xs={12} md={12}>
                        Total
                    </Col>
                    <Col xs={12} md={12} className="text-end">
                        $ {commaSeparator(( getSumToTal() ).toFixed(2))}
                    </Col>
                </Row>

                {
                    checkPriceVariable() && (
                        <p className="mt-10">(*) El monto puede estar sujeto a cambios</p>
                    )
                }
                
                {
                    payments.payments.map((payment: any, key:number) =>(
                    <Form onValuesChange={(e:any) => onChangeArray(e, key)}>
                        <Row key={payment.id} className="mt-6">
                            <Col xs={24} md={10}>
                                {config.show_amount_to_pay ? (
                                    <Form.Item label="Ingrese la cantidad a pagar" name="paid_amount">
                                        <Input type="number" id={"concept-input"+key} value={payment.paid_amount}/>
                                    </Form.Item>
                                ) : (
                                <h4>
                                    <b>Cantidad a pagar:
                                    <Col xs={24} md={{span: 6}}>
                                        $ {commaSeparator(getSumToTal().toFixed(2))}
                                    </Col>
                                    </b>
                                </h4>
                                )}
                            </Col>
                            <Col xs={24} md={{span:13,offset:1}}>
                                <Form.Item label="Seleccione el método de pago" name="type">
                                    <Select className="type" defaultValue={ hasPermission(permissions,"Cajas","create") ? "Efectivo" : config.conekta_enabled ? 'Oxxo' : ''}>
                                    {
                                        hasPermission(permissions,"Cajas","create") && (
                                            <>
                                                <Option value="Efectivo">Efectivo</Option>
                                                <Option value="Tarjeta de crédito">Tarjeta de crédito</Option>
                                                <Option value="Tarjeta de débito">Tarjeta de débito</Option>
                                                <Option value="Meses sin intereses">Meses sin intereses</Option>
                                                <Option value="Transferencia">Transferencia</Option>
                                            </>
                                        )
                                    }   
                                        {
                                            config.conekta_enabled && (
                                                <Option value="Oxxo">Oxxo</Option>
                                            )
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    ))
                }
                <div id="pay_concepts_form">
                    {
                        config.show_amount_to_pay && (
                            <div>
                                <hr></hr>
                                <div className="text-end">
                                    <h4 className="mt-3"><b>Subtotal: ${inputSubtotal()}</b></h4>
                                </div>
                                {
                                    checkPaymentMethod() && (
                                        <Button type="primary" className="mt-4" onClick={() =>{addPaymentMethod()}}>
                                            Agregar método de pago
                                        </Button>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
                {
                    student.external && (
                        <div className="mt-8">
                            <h4><b>Datos de externo:</b></h4>
                            <Row>
                                <Col>
                                    <Form.Item label="Nombre del externo" name="external_name">
                                        <Input type="text" id={"external_name"} name="external_name" onChange={onChangeExternal}/>
                                    </Form.Item>
                                </Col>
                                <Col md={{offset:1}}>
                                    <Form.Item label="Número de trabajador (Opcional)" name="worker_number">
                                        <Input type="text" id={"worker_number"} name="worker_number" onChange={onChangeExternal}/>
                                    </Form.Item>
                                </Col>
                                {
                                    config.external_email && (
                                        <Col md={{offset:1}}>
                                            <Form.Item label="Correo a enviar" name="external_email">
                                                <Input type="text" id={"external_email"} name="external_email" onChange={onChangeExternal}/>
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                            </Row>
                        </div>
                    )
                }
                <div id="calc_cash" className="mt-8">
                    <h4><b>Calcular cambio:</b></h4>
                    <Row>
                        <Col>
                            <Form.Item label="Ingrese la cantidad de efectivo entregada" name="cash">
                                <Input type="number" id={"cash"} onChange={onChangeCash}/>
                            </Form.Item>
                        </Col>
                        <Col md={{offset:1}}>
                            { calculateChange && (
                                <>
                                    <p><b>Cambio:</b> $ { commaSeparator( changeCash.toFixed(2) ) }</p>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
                <div className="notes">
                    <h4>Observaciones</h4>
                    <Row>
                        <Col span={24}>
                            <Form.Item name="notes">
                                <Input maxLength={140} onChange={onChangeNote} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Modal>

            <Modal
                title='Pagos Registrados'
                visible={paymentsWindow}
                onOk={()=>{setPaymentsWindow(false)}}
                onCancel={()=>{setPaymentsWindow(false)}}
                width={"80%"}
                footer={[
                    <Button key="back" onClick={()=>{setPaymentsWindow(false)}} danger ghost>
                    Cerrar
                    </Button>
                ]}
            >
                <Table pagination={false} columns={registeredPaymentsColumns} dataSource={selectedConcept.payments} rowKey="_id"/>
                { 
                    hasPermission(permissions,"Cajas","edit") && (
                        <Space>
                            <Button type="primary" onClick={addPayment}> <PlusOutlined></PlusOutlined> Agregar pago</Button>
                            <Button onClick={() => addReference()} type="link" title="Transferir recibo de pago por referencia">
                                Transferir recibo por referencia
                            </Button>
                        </Space>
                    )
                }
            </Modal>

            <Modal
                visible={showDetail}
                onOk={()=>setShowDetail(false)}
                onCancel={()=>setShowDetail(false)}
                title="Pagos registrados"
                footer={[
                    <Button key="back" onClick={()=>setShowDetail(false)} danger ghost>
                    Cerrar
                    </Button>
                ]}
            >
                <ReactJson src={selectedPayment.payment_detail} />
            </Modal>

            <Modal visible={showChange}
            onOk={()=>setShowChange(false)}
            onCancel={()=>setShowChange(false)}
            title="Descontar concepto"
            footer={[
                <Button type="primary" onClick={()=>confirmChange()}>
                    Guardar
                </Button>,
                <Button danger ghost onClick={()=>setShowChange(false)}>
                    Cerrar
                </Button>
            ]}
            >
                <p><b>Concepto: </b>{selectedConcept.concept?.title}</p>
                <p><b>Cantidad: </b>{selectedConcept && selectedConcept.total_amount ? commaSeparator((selectedConcept.total_amount + (selectedConcept.surcharges_amount ? selectedConcept.surcharges_amount : 0) - selectedConcept.subtotal - selectedConcept.discounts_amount).toFixed(2)) : "0.00"}</p>
                <Form.Item className="mt-3" id={"amount"} label="Ingrese la cantidad a descontar">
                    <Input type="number" name="amount" onChange={(e:any) => onChangeReason(e)}></Input>
                </Form.Item>
                <Form.Item label="Seleccione tipo de descuento" name="type">
                    <Select className="type" defaultValue="Seleccionar tipo de descuento" onChange={(e:any) => onChangeReason(e)}>
                        <Option>Seleccionar un tipo</Option>
                        <Option value="Monto">Monto</Option>
                        <Option value="Recargos">Recargos</Option>
                        <Option value="Monto+Recargos">Monto + Recargos</Option>
                    </Select>
                </Form.Item>
                <Form.Item className="mt-3" id={"reason"} label="Razón">
                    <Input type="text" name="reason" onChange={(e:any) => onChangeReason(e)}></Input>
                </Form.Item>
            </Modal>

            <Modal visible={showEdit}
                onOk={()=>setShowEdit(false)}
                onCancel={()=>setShowEdit(false)}
                title="Editar pago"
                footer={[
                    <Button type="primary" onClick={()=>confirmEdit()}>
                        Guardar
                    </Button>,
                    <Button danger ghost onClick={()=>setShowEdit(false)}>
                        Cerrar
                    </Button>
                ]}
            >
                <p><b>Concepto: </b>{selectedConcept.concept?.title}</p>
                <Form.Item className="mt-3" id={"paid_at"} label="Ingrese la nueva fecha de pago:">
                    <Input type="date" name="paid_at" onChange={onChangePayment} value={updatePayment.paid_at ? updatePayment.paid_at.split('T')[0] : ''}></Input>
                </Form.Item>
                <Form.Item className="mt-3" id={"type"} label="Ingrese un tipo:">
                    <Input type="text" name="type" onChange={onChangePayment} value={updatePayment.type}></Input>
                </Form.Item>
                <Form.Item label="Seleccione estatus de pago" name="status_paid">
                    <Select className="type" defaultValue="Seleccionar estatus de pago" value={ String( updatePayment.status_paid ) } onChange={(e:any) => onChangePayment(e, "status_paid")}>
                        <Option>Seleccionar un estatus</Option>
                        <Option value="false">Por pagar</Option>
                        <Option value="true">Pagado</Option>
                    </Select>
                </Form.Item>
                <Form.Item className="mt-3" id={"type"} label="Monto pagado:">
                    <Input type="text" name="paid_amount" onChange={onChangePayment} value={updatePayment.paid_amount}></Input>
                </Form.Item>
            </Modal>

            <Modal visible={showNewPayment}
                onOk={()=>setNewPayment(false)}
                onCancel={()=>setNewPayment(false)}
                title="Editar pago"
                footer={[
                    <Button type="primary" onClick={()=>confirmNewPayment()}>
                        Guardar
                    </Button>,
                    <Button danger ghost onClick={()=>setNewPayment(false)}>
                        Cerrar
                    </Button>
                ]}
            >
                <p><b>Concepto: </b>{selectedConcept.concept?.title}</p>
                <Form.Item className="mt-3" id={"paid_at"} label="Ingrese la nueva fecha de pago:">
                    <Input type="date" name="paid_at" onChange={onChangePayment} value={updatePayment.paid_at ? updatePayment.paid_at.split('T')[0] : ''}></Input>
                </Form.Item>
                <Form.Item className="mt-3" id={"type"} label="Ingrese un tipo:">
                    <Input type="text" name="type" onChange={onChangePayment} value={updatePayment.type}></Input>
                </Form.Item>
                <Form.Item label="Seleccione estatus de pago" name="status_paid">
                    <Select className="type" defaultValue="Seleccionar estatus de pago" value={ String( updatePayment.status_paid ) } onChange={(e:any) => onChangePayment(e, "status_paid")}>
                        <Option>Seleccionar un estatus</Option>
                        <Option value="false">Por pagar</Option>
                        <Option value="true">Pagado</Option>
                    </Select>
                </Form.Item>
                <Form.Item className="mt-3" id={"type"} label="Monto pagado:">
                    <Input type="text" name="paid_amount" onChange={onChangePayment} value={updatePayment.paid_amount}></Input>
                </Form.Item>
            </Modal>

            <Modal visible={showCancelReceipt}
                onOk={()=>setCancelReceipt(false)}
                onCancel={()=>setCancelReceipt(false)}
                title="Cancelar recibo de pago"
                footer={[
                    <Button type="primary" onClick={()=>confirmCancelPayment()}>
                        Guardar
                    </Button>,
                    <Button danger ghost onClick={()=>setCancelReceipt(false)}>
                        Cerrar
                    </Button>
                ]}
            >
                <p><b>Concepto: </b>{selectedConcept.concept?.title}</p>
                <Form.Item className="mt-3" id={"paid_at"} label="Ingrese una razón:">
                    <Input type="text" name="reason" onChange={onChangeCancelReason}></Input>
                </Form.Item>
            </Modal>

            <Modal visible={showNewReference}
                onOk={()=>setNewReference(false)}
                onCancel={()=>setNewReference(false)}
                title="Transferir recibo de pago"
                footer={[
                    <Button type="primary" onClick={()=>confirmNewReference()}>
                        Guardar
                    </Button>,
                    <Button danger ghost onClick={()=>setNewReference(false)}>
                        Cerrar
                    </Button>
                ]}
            >
                <p><b>Concepto: </b>{selectedConcept.concept?.title}</p>
                <Form.Item className="mt-3" id={"type"} label="Ingresa el código completo del recibo a copiar:">
                    <Input type="text" name="paid_amount" onChange={(e:any)=>{ setReference(e.target.value) }} ></Input>
                </Form.Item>
            </Modal>

            <Modal visible={showTransferPayment}
                onOk={()=>setTransferPayment(false)}
                onCancel={()=>setTransferPayment(false)}
                title="Transferir recibo de pago"
                footer={[
                    <Button type="primary" onClick={()=>confirmTransfer()}>
                        Guardar
                    </Button>,
                    <Button danger ghost onClick={()=>setTransferPayment(false)}>
                        Cerrar
                    </Button>
                ]}
            >
                <Form.Item className="mt-3" id={"student"} label="Buscar alumno:">
                    <Input type="text" name="student" onChange={ (e:any) => onChangeTransfer(e.target.value, 'search') } ></Input>
                </Form.Item>

                {
                    students.length > 0 && (
                        <div>
                            <Form.Item className="mt-3" id={"student-data"} label="Selecciona un alumno:">
                                <Select onChange={(value:string)=>onChangeTransfer(value, 'student')}>
                                    <Option children={undefined} />
                                    {
                                        students.map((student:Student, key:number) => (
                                            <Option key={key} value={student._id}>{ `${student.student_id} - ${student.name} ${student.first_lastname} ${student.second_lastname}` }</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item className="mt-3" id={"student-data"} label="Selecciona un ciclo:">
                                <Select onChange={(value:string)=>onChangeTransfer(value, 'cycle')}>
                                    <Option children={undefined} />
                                    {
                                        cycles.map((cycle:SchoolCycle, key:number) => (
                                            <Option key={key} value={cycle._id}>{ `${cycle.name}` }</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                    )
                }
                {
                    studentConcepts.length > 0 && (
                        <Form.Item className="mt-3" id={"concept"} label="Selecciona un concepto:">
                            <Select onChange={(value:string)=>onChangeTransfer(value, 'concept')}>
                                <Option children={undefined} />
                                {
                                    studentConcepts.map((concept:Status, key:number) => (
                                        <Option key={key} value={concept._id}>{ `${concept.concept.title}` }</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    )
                }
            </Modal>

            <Modal visible={showEditPaymentConcept}
                onOk={()=>setShowEditPaymentConcept(false)}
                onCancel={()=>setShowEditPaymentConcept(false)}
                title="Editar Concepto de Alumno"
                footer={[
                    <Button type="primary" onClick={()=>confirmEditPaymentConcept()}>
                        Guardar
                    </Button>,
                    <Button danger ghost onClick={()=>setShowEditPaymentConcept(false)}>
                        Cerrar
                    </Button>
                ]}
            >
                <p><b>Concepto: </b>{selectedConcept.concept?.title}</p>
                <Form.Item className="mt-3" id={"limit_date"} label="Fecha límite de pago:">
                    <Input type="date" name="limit_date" onChange={onChangePaymentConcept} value={updateConcept.limit_date ? updateConcept.limit_date.split('T')[0] : ''}></Input>
                </Form.Item>
                <Form.Item className="mt-3" id={"total_amount"} label="Monto a pagar:">
                    <Input type="text" name="total_amount" onChange={onChangePaymentConcept} value={updateConcept.total_amount}></Input>
                </Form.Item>
                <Form.Item label="Estatus de pago" name="status_complete_paid">
                    <Select className="type" defaultValue="Seleccionar un estatus" value={ String(updateConcept.status_complete_paid) } onChange={(e:string) => onChangePaymentConcept(e,'status_complete_paid')}>
                        <Option>Seleccionar un estatus</Option>
                        <Option value="true">Pagado</Option>
                        <Option value="false">No pagado</Option>
                    </Select>
                </Form.Item>                                
                <React.Fragment>                            
                    <p className="m-0"><b>Recargos</b></p>
                    <Button type={"link"} onClick={() => addSurcharge()}> <PlusOutlined></PlusOutlined> Agregar recargo </Button>

                    {
                        updateConcept.surcharges && updateConcept.surcharges.map((surcharge:any, key:number)=>(
                            <Row key={surcharge._id} align="middle">
                                <Col xs={24} md={10}>                        
                                    <Form.Item className="mt-3" id={"total_amount_"+surcharge._id} label="Recargo:" labelCol={{xs:24}} labelAlign="left">
                                        <Input type="text" name="surcharge" onChange={(e:any) => onChangePaymentSurcharge(e, key)} value={ surcharge.surcharge }></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={{span:10,offset:1}}>                        
                                    <Form.Item className="mt-3" id={"total_amount_"+surcharge._id} label="Mes: (Enero - 0, Diciembre - 11)" labelCol={{xs:24}} labelAlign="left">
                                        <Input type="text" name="month" onChange={(e:any) => onChangePaymentSurcharge(e, key)} value={ surcharge.month }></Input>
                                    </Form.Item>
                                </Col>
                                <Col xs={{offset:1, span:2}}>
                                    <Button type="primary" onClick={()=>removeSurcharge(key)}>
                                        <CloseOutlined></CloseOutlined>
                                    </Button>
                                </Col>
                            </Row>
                        ))
                    }
                </React.Fragment>
                {
                    updateConcept.discounts && updateConcept.discounts.length > 0 && (
                        <React.Fragment>
                            <p className="m-0"><b>Descuentos</b></p>
                            {
                                updateConcept.discounts && updateConcept.discounts.map((discount:any, key:number)=>(
                                    <Row key={discount._id} align="middle">
                                        <Col>                        
                                            <Form.Item className="mt-3" id={"total_amount_"+discount._id} label="Descuento:">
                                                <Input type="text" name="discount" onChange={(e:any) => onChangePaymentDiscount(e, key)} value={ discount.discount }></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={{offset:1}}>
                                            {
                                                discount.status ? 'Activo' : 'Cancelado'
                                            }
                                        </Col>
                                        <Col xs={{offset:2}}>
                                            <Button type="primary" onClick={()=>removeDiscount(key)}>
                                                <CloseOutlined></CloseOutlined>
                                            </Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </React.Fragment>
                    )
                }
            </Modal>
            <Modal
            title='Dar de baja al estudiante'
            visible={showDischargeStudent}
            onOk={dischargeStudent}
            onCancel={()=>setShowDischargeStudent(false)}
            >
                <Row>
                    <Col>
                        <p>Por favor seleccione la fecha en la que el estudiante fue dado de baja:</p>
                    </Col>
                    <Col>
                        <DatePicker 
                            onChange={(_date, dateString)=>setDateDischarge(new Date(new Date(dateString).setHours(0,0,0)))} 
                            defaultValue={moment(dateDischarge, 'YYYY/MM/DD')} 
                            format='YYYY/MM/DD' 
                        />
                    </Col>
                    <Col>
                        <p>
                            Por favor tome en consideración que a partir de la fecha que ingrese 
                            se eliminaran los pagos sin saldar relacionados al estudiante.
                        </p>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default CashPayments;