import {useEffect, useState } from 'react';
import {Concept, ResponseResult } from '../types';
import { request } from '../services/Request';
import Swal from 'sweetalert2';
import {Table, Modal, Button, Card, Space, Tooltip } from "antd";
import ConceptEdit from './ConceptEdit';
import { format } from 'date-fns';

function ConceptList({cycle}:{cycle:string}){

    const [concepts, setConcepts] = useState<Concept[]>([]);
    const [selectedConcept, setSelectedConcept] = useState<Partial<Concept>>({});
    const [showRemove, setRemove] = useState(false);
    const [editData, setEditData] = useState<Partial<Concept>>({});
    

    useEffect( () => {
        getConcepts();
      }, []);

      const getConcepts = () => {
        request('/api/concept/list/'+cycle,{})
        .then((result:ResponseResult)=>{
           if(result.data){
            setConcepts(result.data);
           }
        })
      }
      const confirmRemove = (concept:Concept) => {
        setSelectedConcept(concept);
        setRemove(true);
      } 

      const removeConcept = () => {
        setRemove(false);
        Swal.fire("Concepto","Eliminando concepto...");
        Swal.showLoading();
        request('/api/concept/'+selectedConcept._id,{
            method: 'DELETE'
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                getConcepts();
            }else{
                Swal.fire("Eliminar concepto" ,"No se pudo elimininar el concepto debido a que algunos alumnos ya lo tienen asignado", "error");
            }
        })
      }

      const showEdit = (concept : Concept) => {
        setEditData(concept); 
        setTimeout(()=>{
            const element = document.getElementById("concept-edit-form");
            element?.scrollIntoView({block: "start"}) as unknown as HTMLElement;
        },500);
      }

      const showClose = (concept: Concept) => {
          Swal.fire({
              title: 'Deshabilitar concepto',
              text: `¿Está seguro de deshabilitar el concepto ${ concept.title+"-"+format(new Date(concept.limit_date), 'dd/MM/yyyy')}?`,
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: 'Sí',
              cancelButtonText: 'No'
          })
          .then((result)=>{
              if(result.isConfirmed){
                Swal.fire("Deshabilitar concepto", "Cargando...");
                Swal.showLoading();
                request('/api/concept/close/'+concept._id,{
                    method: 'GET'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        Swal.fire("Deshabilitar concepto" ,"Se ha creado la petición para deshabilitar el concepto.", "success");
                    }else{
                        Swal.fire("Deshabilitar concepto" ,"No se pudo deshabilitar", "error");
                    }
                })
              }
          })
      }

      const showOpen = (concept: Concept) => {
        Swal.fire({
            title: 'Habilitar concepto',
            text: `¿Está seguro de Habilitar el concepto ${ concept.title+"-"+format(new Date(concept.limit_date), 'dd/MM/yyyy')}?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        })
        .then((result)=>{
            if(result.isConfirmed){
              Swal.fire("Habilitar concepto", "Cargando...");
              Swal.showLoading();
              request('/api/concept/open/'+concept._id,{
                  method: 'GET'
              })
              .then((result:ResponseResult)=>{
                  Swal.close();
                  if(result.data){
                      Swal.fire("Habilitar concepto" ,"Se ha creado la petición para habilitar este concepto, verificar cada 5 minutos.", "success");
                  }else{
                      Swal.fire("Habilitar concepto" ,"No se pudieron habilitar los pagos", "error");
                  }
              })
            }
        })
    }
      const columns = [
        {
            title: 'Concepto',
            dataIndex: 'concept'
        },
        {
            title: 'Título del concepto',
            dataIndex: 'title'
        },
        {
            title: 'Fecha límite de pago',
            render: (text: string, concept: Concept, index: number) => (
                <Space>
                    { format(new Date(concept.limit_date), 'dd/MM/yyyy')}
                </Space>
            )
        },
        {
            title: 'Opciones',
            render: (text:string, concept: Concept, index: number) => (
                <Space>
                    <Button type="primary" ghost onClick={() =>{showEdit(concept)}}>
                        Editar
                    </Button>
                    <Tooltip title={`Este concepto se deshabilitará para los alumnos que no lo han pagado`}>
                        <Button danger ghost onClick={() =>{showClose(concept)}}>
                            Cerrar
                        </Button>
                    </Tooltip>
                    <Tooltip title={`Este concepto se habilitará solamente para aquellos alumnos que no han pagado y que hayan sido previamente deshabilitados`}>
                        <Button danger ghost onClick={() =>{showOpen(concept)}}>
                            Abrir
                        </Button>
                    </Tooltip>
                    <Button danger ghost onClick={() =>{confirmRemove(concept)}}>
                        Eliminar
                    </Button>
                </Space>
            )
        },
      ];

    return (
        <div className="new-heading">

            <Card>
                <h3>Lista de conceptos de pago</h3>                
                <Table className="mt-8" pagination={false} dataSource={concepts} columns={columns}/>
            </Card>

            {editData.title !== null && editData._id &&
                <div className="mt-5 mb-5" id="conceptEdit">
                    <ConceptEdit data={editData} />
                </div>
            }
            <Modal 
            visible={showRemove} 
            onOk={()=>{setRemove(false)}}
            onCancel={()=>{setRemove(false)}}
            title="Eliminar concepto"
            footer={[
                 <Button type="primary" onClick={()=>{setRemove(false)}}>
                    Cancelar
                </Button>,
                <Button danger onClick={removeConcept}>
                    Eliminar
                </Button>

            ]}
            >
                ¿Está seguro de eliminar el concepto {selectedConcept?.title}?
            </Modal>
            
        </div>
    );
}

export default ConceptList;