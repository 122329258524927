import React, {useEffect,useState} from 'react';
import { ResponseResult, Student, SchoolCycle, Payment} from '../../types';
import { Row, Col, Card, Table, Form, Input, Space, Button, Select } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function ImportPayments(){

    const [students, setStudents] = useState<Student[]>([]);
    const [cycles, setCycles] = useState<SchoolCycle[]>([]);
    const [cycleData, setCycleData] = useState<Partial<SchoolCycle>>({});
    const [studentData, setStudentData] = useState<Partial<Student>>({});
    const [studentPayments, setStudentPayments] = useState<Payment[]>([]);
    const { Option } = Select;

    useEffect(()=>{
        getCycles();
    }, []);

    const getCycles = () => {
        Swal.fire("Ciclos escolares", "Realizando petición...");
        Swal.showLoading();
        request('/api/cycle',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setCycles(result.data);
            }
        })
    }

    const onSubmit = () => {
        setStudentPayments([]);
        Swal.fire("Buscar pagos", "Realizando petición...");
        Swal.showLoading();
        request("/api/payment/searchPayments/", {
            method: "POST",
            body: JSON.stringify({studentData, cycleData})
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setStudentPayments(result.data);
            }else{
                Swal.fire("Buscar pagos", "Ocurrió un error al buscar los pagos", "error");
            }
        })
    }

    const getPayments = () => {
        setStudentPayments([]);
        Swal.fire("Buscar pagos", "Realizando petición...");
        Swal.showLoading();
        request("/api/payment/searchPayments/", {
            method: "POST",
            body: JSON.stringify({studentData, cycleData})
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setStudentPayments(result.data);
            }else{
                Swal.fire("Buscar pagos", "Ocurrió un error al buscar los pagos", "error");
            }
        })
    }

    const selectStudent = (student:Student) => {
        const studentsData = students;
        for(let i = 0; i<studentsData.length;i++){
            if( student._id === studentsData[i]._id ){
                studentsData[i].selected = !studentsData[i].selected;
            }else{
                studentsData[i].selected = false;
            }
        }
        setStudentData({student:student._id});
        setStudents(studentsData);
    }

    const onSelectedCycle = (e:any) => {
        const data = cycleData;
        data['cycle'] = e;
        setCycleData(data);
    }

    const onChangeAmount = (e:any ,index: number, concept:any) => {
        const data:any = studentPayments;
        let amount = Number(e.target.value);
        if(amount < 0){
            (document.getElementById('concept-input-'+index) as HTMLInputElement ).value = "0";
            amount = 0;
        }else if(amount > (concept.total_amount + concept.surcharges_amount)){
            (document.getElementById('concept-input-'+index) as HTMLInputElement ).value = String((concept.total_amount + concept.surcharges_amount));
            amount = (concept.total_amount + concept.surcharges_amount );
        }
        data[index]['amount'] = amount;
        setStudentPayments(data);
    }

    const onChangeDate = (e:React.ChangeEvent<HTMLInputElement>, index: number) => {
        const data:any = studentPayments;
        data[index]['paid_at'] = e.target.value;
        setStudentPayments(data);
    }

    const payConcepts = () => {
        Swal.fire("Pagar", "Registrando el pago...");
        Swal.showLoading();
        request('/api/payment/importPayments/',{
            method: 'POST',
            body: JSON.stringify({
                concepts: studentPayments,
                student: studentData._id
            })
        })
        .then((result:ResponseResult)=>{
            Swal.hideLoading();
            if(result.data){
                Swal.fire("Pagar", "Se ha registrado el pago correctamente", "success")
                .then(()=>{
                    getPayments();
                });
            }else{
                Swal.fire("Pagar", "Ocurrió un error al registrar el pago.", "error");
            }
        })
    }

    const onChange = (e: any) => {
        const enrollment = e.target.value;
        getStudents(enrollment)        
    }

    const onChangeName = (e: any) => {
        const studentName = e.target.value;
        getStudentsByName(studentName)        
    }

    const getStudents = (enrollment:string) => {
        request('/api/student?enrollment='+enrollment+'&search={}',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudents(result.data);
            }
        })
    }

    const getStudentsByName = (name:string) => {
        request('/api/student?name='+name+'&search={}',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudents(result.data);
            }
        })
    }

    const studentsColumns = [
        {
            title: 'Matrícula',
            dataIndex: 'student_id'
        },
        {
            title: 'Nombre',
            render: (text: string, student: Student, index: number) =>(
                <Space>
                    {student.name+' '+student.first_lastname+' '+student.second_lastname}
                </Space>
            )
        },
        {
            title: '',
            render: (text:string, record: Student, index: number) => (
                <Space>
                    {
                        record.selected ? (
                            <Button danger onClick={()=>selectStudent(record)}>Deseleccionar</Button>
                                ) : (
                            <Button type="primary" ghost className="btn btn-primary" onClick={()=>selectStudent(record)}>Seleccionar</Button>
                        )
                    }
                </Space>
            )
        }
    ]

    const studentDebtsColumns = [
        {
            title: 'Concepto',
            render: (text:string, record: Payment, index: number) => (
                <Space>
                    {record.concept.title}
                </Space>
            )
        },
        {
            title: 'Importe',
            width: '200px',
            render: (text:string, record: Payment, index: number) => (
                <div className="text-end">
                    $ {(record.total_amount + record.surcharges_amount).toFixed(2)}
                </div>
            )
        },
        {
            title: 'Pagado',
            width: '200px',
            render: (text:string, record: Payment, index: number) => (
                <div className="text-end">
                    $ {record.subtotal.toFixed(2)}
                </div>
            )
        },
        {
            title: 'A pagar',
            width: '280px',
            render: (text:string, record: Payment, index: number) => (
                <div className="text-end pl-4">
                    {
                        (record.total_amount + record.surcharges_amount - record.subtotal ) > 0 && (
                            <Form.Item name="paid_amount" label="$">
                                <Input type="number" id={"concept-input-" + index} onChange={(e)=>onChangeAmount(e, index, record)} defaultValue={(record.total_amount + record.surcharges_amount) - record.subtotal} />
                            </Form.Item>
                        )
                    }
                </div>
            )
        },
        {
            title: 'Fecha de pago',
            render: (text:string, record: Payment, index: number) => (
                <Space>
                    {
                        (record.total_amount + record.surcharges_amount - record.subtotal ) > 0 && (
                            <Form.Item className="mb-3" id={"concept-input"}>
                                <Input type="date" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>onChangeDate(e, index)} defaultValue={new Date().toISOString().split("T")[0]} />
                            </Form.Item>
                        )
                    }
                </Space>
            )
        },
    ]

return(
    <div>
        <Row>
            <Col xs={24} md={{span:18,offset:3}}>
                <Card>
                    <h2>Importación de pagos</h2> 
                        <Form.Item label="Búsqueda por matricula" name="searchByEnrollment" >
                            <Input type="text" onChange={onChange}/>
                        </Form.Item>
                        <Form.Item label="Búsqueda por nombre" name="searchByEnrollment" >
                            <Input type="text" onChange={onChangeName}/>
                        </Form.Item>
                        <h4>Seleccionar un alumno:</h4>
                        <Table pagination={false} dataSource={students} columns={studentsColumns}/>
                        
                        <Form.Item label="Seleccionar ciclo escolar:" name="selectSchoolCycle">
                                <Select className="cycle" placeholder="" onChange={onSelectedCycle} defaultValue={"null"}>
                                <Option value="null">Seleccionar ciclo</Option>
                                    {
                                    cycles.map((cycle:SchoolCycle, key:number)=>(
                                        <Option key={key} value={cycle._id}>{cycle.name}</Option>
                                    ))   
                                    }
                                </Select>
                        </Form.Item>
                    <Button onClick={onSubmit} type="primary">Buscar conceptos</Button>  
                </Card>
                <Card>
                    <h3>Adeudos del alumno</h3>
                        <Table bordered={true} pagination={false} columns={studentDebtsColumns} dataSource={studentPayments}/>
                        <Button type="primary" onClick={payConcepts}>Marcar como pagado</Button>
                </Card>
            </Col>
        </Row>
    </div>  
    )
}

export default ImportPayments;