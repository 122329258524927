import React, { useEffect, useState } from 'react';
import { ResponseResult, Scholarship, RouteParam } from '../../types';
import {Card, Row, Col, Button, Form, Input} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

type Rejected = {
    student: string;
    reason: string;
}

function ScholarshipToDelete(){

    const { id } = useParams<RouteParam>();
    const [scholarship, setScholarship] = useState<Partial<Scholarship>>({});
    const [students, setStudents] = useState<Rejected[]>([]);
    const { TextArea } = Input;

    useEffect(()=>{
        getScholarship();
    }, []);

    const getScholarship = () => {
        Swal.fire("Cancelar beca", "Cargando...");
        Swal.showLoading();
        request("/api/scholarship/"+id,{
            method:'GET',
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setScholarship(result.data);
            }else{
                Swal.fire("Asignar beca", "Ocurrió un error al cargar la beca", "error");
            }
        })
    }

    const submit = (values:{student_ids:string}) => {
        Swal.fire("Eliminar beca", "Eliminar beca...");
        Swal.showLoading();
        request("/api/scholarship/cancel/"+id,{
            method:'DELETE',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setStudents( result.data.rejected );
                Swal.fire("Cancelar beca", "Se han eliminado las becas de los alumnos.", "success")
            }else{
                Swal.fire("Cancelar beca", "Ocurrió un error al eliminar la beca", "error");
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:16,offset:4}}>
                    <Card>
                        <h2>Eliminar beca { scholarship.name }</h2>
                        <Row>
                            <Col xs={24} md={24}>
                                <Form onFinish={submit} initialValues={scholarship}>
                                    <Form.Item label="Ingresa las matrículas de los alumnos a eliminar (separados por salto de línea)" name="student_ids" rules={[
                                                {
                                                    required: true,
                                                    message: 'Ingresa las matrículas de los alumnos a eliminar',
                                                },
                                            ]}>
                                        <TextArea rows={4} />
                                    </Form.Item>

                                    <Button type="primary" htmlType="submit" className="mb-10">Eliminar becas</Button>
                                </Form>
                            </Col>
                            <Col xs={24} md={24}>
                                <p><b>Fallidos:</b></p>
                                {
                                    students.map((student:Rejected,key:number)=>(
                                        <p key={key}><b>Alumno: </b> { student.student }. <b>Razón: </b>{student.reason}</p>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default ScholarshipToDelete;