import React, { useContext, useEffect, useState } from 'react';
import { ResponseResult, Package } from '../../types';
import {Card, Table, Row, Col, Button, Dropdown, Menu} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { DownOutlined } from '@ant-design/icons';
import ConfigContext from '../../context/Config';
import commaSeparator from '../../services/CommaSeparator';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';

function PackagesList(){

    const [packages, setPackages] = useState<Package[]>([]);
    const { components: { packages: config } } = useContext(ConfigContext);
    const { permissions } = useContext(SessionContext);

    useEffect(()=>{
        getPackages();
    }, []);

    const getPackages = () => {
        Swal.fire("Paquetes", "Realizando petición...");
        Swal.showLoading();
        request('/api/package',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setPackages(result.data);
            }
        })
    }

    const removePackage = (payment_package:Package) => {
        Swal.fire({
            title: 'Eliminar paquete',
            html: '¿Estás seguro de eliminar el paquete '+ payment_package.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar paquete", "Realizando petición...");
                Swal.showLoading();
                request("/api/package/"+payment_package._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getPackages();
                    }else{
                        Swal.fire("Eliminar paquete", "Ocurrió un error al eliminar el paquete", "error");
                    }
                })
            }
        })
    }

    const menu = (id:string, paymentPackage:Package) =>{
        return (
            <Menu>
                {
                    hasPermission(permissions,"Paquetes","edit") && (
                        <Menu.Item>
                            <a href={"/packages/edit/"+id}>
                                Editar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions,"Paquetes","edit") && (
                        <React.Fragment>
                            <Menu.Item>
                                <div onClick={()=>{removePackage(paymentPackage)}}>
                                    Eliminar
                                </div>
                            </Menu.Item>
                            <Menu.Item>
                                <a href={"/packages/assign/"+id}>
                                    Asignar paquete
                                </a>
                            </Menu.Item>
                        </React.Fragment>  
                    )
                }
                <Menu.Item>
                    <a href={"/packages/assigned-list/"+id}>
                        Ver asignados
                    </a>
                </Menu.Item>
            </Menu>
        )
    }

    const columns = [];
    columns.push({
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name'
    })
    columns.push({
        title: 'Cantidad mensual',
        render: (text: string, record: Package, index: number) => (
            <div className="text-end">
                $ {record.monthly_amount ? commaSeparator(record.monthly_amount.toFixed(2)) : '0.00'}
            </div>
        )
    })
    if (config.admin_payment_enabled) {
        columns.push({
            title: 'Cantidad gastos administrativos',
            render: (text: string, record: Package, index: number) => (
                <div className="text-end">
                    $ {record.administrative_expenses ? commaSeparator(record.administrative_expenses.toFixed(2)) : '0.00'}
                </div>
            )
        })
    }
    columns.push({
        title: 'Cantidad reinscripción',
        render: (text: string, record: Package, index: number) => (
            <div className="text-end">
                $ { record.re_enrollment_amount ? commaSeparator(record.re_enrollment_amount.toFixed(2)) : '0.00'}
            </div>
        )
    })
    columns.push({
        title: 'Opciones',
        key: 'id',
        dataIndex: 'id',
        render: (text:string, record:Package) => (
            <div className="text-end">
                    <Dropdown overlay={menu(record._id, record)} placement="bottomLeft" arrow>
                    <Button type="primary" ghost>
                        Opciones <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
        ),
    })

    const downloadReport = () => {
        Swal.fire("Reporte paquete", "Generando reporte...");
            Swal.showLoading();
            download(`/api/student/report-package`)
    }

    const download = (url: string) => {
        const a = document.createElement('a')
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:16,offset:4}}>
                    <Card>
                        <h2 className="mb-4">Listado de paquetes</h2>
                        {
                            hasPermission(permissions,"Paquetes","create") && (                                
                                <Row>
                                    <Col>
                                        <Button className="mb-6" type="primary" href="/packages/new">Nuevo paquete</Button>
                                    </Col>
                                    <Col>
                                        <Button className="mb-6 ml-6" type="primary" href="/packages/transfer">Transferir asignados</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Row>
                            <Col>
                                <Button danger onClick={downloadReport}>
                                    Descargar lista
                                </Button>
                            </Col>
                        </Row>
                        <Table dataSource={packages} columns={columns} rowKey="id"/> 
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default PackagesList;