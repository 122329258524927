import React, { useEffect, useState } from 'react';
import { ResponseResult, Package } from '../../types';
import {Card, Row, Col, Button, Form, Select} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';

function PackagesTransfer(){
  const { Option } = Select;

    const [ packages, setPackages ] = useState<Package[]>([]);

    useEffect(()=>{
        getPackages();
    }, []);

    const getPackages = () => {
        Swal.fire("Paquetes", "Realizando petición...");
        Swal.showLoading();
        request('/api/package',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setPackages(result.data);
            }
        })
    }

    const submit = (values: { source:string, target: string }) => {
        Swal.fire("Nuevo paquete", "Guardando paquete...");
        Swal.showLoading();
        request("/api/package/transfer",{
            method:'POST',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/packages";
            }else{
                Swal.fire("Transferir", "Ocurrió un error al transferir el paquete", "error");
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:16,offset:4}}>
                    <Card>
                        <h2>Transferir alumnos asignados</h2>
                        <Form onFinish={submit}>
                          <Form.Item label="Origen" name="source" rules={[
                                      {
                                          required: true,
                                          message: 'Selecciona el origen',
                                      },
                                  ]}>
                              <Select className="status_description">
                                { packages && packages.map((p) => <Option value={ p._id }>{ p.name }</Option>)}
                              </Select>
                          </Form.Item>
                          <Form.Item label="Destino" name="target" rules={[
                                      {
                                          required: true,
                                          message: 'Selecciona un destino',
                                      },
                                  ]}>
                              <Select className="status_description">
                                { packages && packages.map((p) => <Option value={ p._id }>{ p.name }</Option>)}
                              </Select>
                          </Form.Item>
                            <Button type="primary" htmlType="submit">
                               Guardar
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default PackagesTransfer;