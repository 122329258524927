import React, { useEffect, useState, useContext } from 'react';
import { ResponseResult, Student } from '../../types';
import {
    Card,
    Table,
    Row,
    Col,
    Button,
    Space,
    Form,
    Input,
    Radio,
    Menu,
    Dropdown, 
    Modal 
} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { DownOutlined } from '@ant-design/icons';
import ConfigContext from '../../context/Config';
import SessionContext from '../../context/Session';
import hasPermission from '../../services/HasPermission';


function ApplicantList(){

    const [students, setStudents] = useState<Student[]>([]);
    const [searchParam, setSearchParam] = useState('')
    const searchAllStatuses = false
    const { components: { students: configStudents } } = useContext(ConfigContext);
    const { permissions } = useContext(SessionContext);
    const [ showModal, setModal] = useState(false);
    const [selectedApplicant, setApplicant] = useState<Partial<Student>>({});
    const [type, setType] = useState('');
    const [amount, setAmount] = useState(0)

    useEffect(()=>{
        searchStudents(searchAllStatuses);
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            searchStudents(searchAllStatuses)
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
      }, [searchParam])

    const searchStudents = async (status:boolean) => {
        const result:ResponseResult = await request(`/api/applicant`+(searchParam ? '?search='+searchParam : ''),{})
        if(result.data){
            setStudents(result.data);
        }
    }

    const removeStudent = (student:Student) => {
        Swal.fire({
            title: 'Eliminar aspirante',
            html: '¿Estás seguro de eliminar al aspirante '+ student.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Eliminar aspirante", "Realizando petición...");
                Swal.showLoading();
                request("/api/applicant/"+student._id,{
                    method: 'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    window.location.reload();
                })
            }
        })
    }

    const onChangeSearchParam = (e: any) => {
        const input = e.target.value;
        setSearchParam(input)
    }

    const generateConcept = async (student:Student) => {
        setApplicant(student);
        setModal(true);
    }

    const confirmConcept = async () => {
        Swal.fire({
            title: 'Generar inscripción',
            html: '¿Estás seguro de generar la inscripción al aspirante '+ selectedApplicant.name +'?',
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No'
        }).then((result:any)=>{
            if(result.isConfirmed){
                Swal.fire("Generar inscripción", "Realizando petición...");
                Swal.showLoading();
                request("/api/applicant/inscription",{
                    method: 'POST',
                    body: JSON.stringify({
                        applicant: selectedApplicant._id,
                        type: type,
                        amount: amount
                    })
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        if(type === 'Oxxo' || type === 'Efectivo'){
                            window.open(result.data.url,"_blank");
                            Swal.fire("Generar inscripción", "Se ha henerado el concepto de pago.", "success");
                        }else{
                            Swal.fire("Generar inscripción", "Se ha enviado un correo al aspirante con la liga de pago.", "success");
                        }
                        searchStudents(searchAllStatuses);
                        setModal(false);
                    }else{
                        Swal.fire("Aspirantes", "Ocurrio un error al intentar generarle el concepto el aspirante", "error");
                    }
                })
            }
        })
    }

    const onChangeType = (e:any) => {
        setType( e.target.value );
    }

    const onChangeAmount = (e:any) => {
        setAmount( e.target.value );

    }

    const printStatus = (student:Student) => {
        if(student.payment.length == 0){
            return 'Sin concepto';
        }else if( student.payment[0].status_complete_paid){
            return 'Pagado';
        }else{
            return 'Pendiente pago';
        }
    }

    const menu = (student: Student) =>{
        return (
            <Menu>
                <Menu.Item>
                    <a onClick={() => { generateConcept(student) }}>
                        Generar concepto de pago
                    </a>
                </Menu.Item>                    
                {
                    hasPermission(permissions, "Alumnos", "edit") && (
                        <Menu.Item>
                            <a href={"/applicants/edit/"+student._id}>
                                Editar
                            </a>
                        </Menu.Item>
                    )
                }
                {
                    hasPermission(permissions, "Alumnos", "remove") && (
                        <React.Fragment>
                            <Menu.Item>
                                <span onClick={()=>{removeStudent(student)}}>
                                    Eliminar
                                </span>
                            </Menu.Item>
                        </React.Fragment>
                    )
                }
            </Menu>
        )
    }

    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            render: (text: string, student: Student, index: number) =>(
                <Space>
                    {student.name+' '+student.first_lastname+' '+student.second_lastname}
                </Space>
            )
        },
        {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Teléfono',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'CURP',
            dataIndex: 'curp',
            key: 'curp'
        },
        {
            title: 'Estatus inscripción',
            key: 'id',
            dataIndex: 'id',
            render: (text:string, student:Student) => (
                <Space>
                    { printStatus(student) }
                </Space>
            ),
        },
        {
            title: 'Opciones',
            key: 'id',
            dataIndex: 'id',
            render: (text:string, student:Student) => (
                <Space>
                     <Dropdown overlay={menu(student)} placement="bottomLeft" arrow>
                        <Button type="primary" ghost>
                            Opciones <DownOutlined />
                        </Button>
                    </Dropdown>
                </Space>
            ),
        }
    ]


    return (
        <div>
            <Row>
                <Col xs={12} md={{span:20,offset:2}}>
                    <Card>
                        <h2>Listado de aspirantes</h2>
                        <Form.Item label="Búsqueda" name="searchByEnrollment" >
                            <Input type="text" onChange={onChangeSearchParam}/>
                        </Form.Item>
                        {
                            ( hasPermission(permissions,"Alumnos","create") && configStudents.new_form ) && (                                
                                <Row>
                                    <Col>
                                        <Button className="mt-2 mb-4" type="primary" href={"/applicants/new"}>Nuevo aspirante</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        <Table pagination={{defaultPageSize: 50}} dataSource={students} columns={columns} rowKey="id"/>
                    </Card>
                </Col>
            </Row>
            <Modal title="Generar inscripción" visible={showModal} onCancel={()=>setModal(false)} footer={[
                <Button key="back" onClick={()=>setModal(false)}>
                    Cancelar
                </Button>,
                <Button type="primary" onClick={confirmConcept}>
                    Generar
                </Button>
            ]}>
                <p><b>Alumno: </b> { selectedApplicant.name+" "+selectedApplicant.first_lastname+" "+selectedApplicant.second_lastname }</p>
                <Form.Item label='Cantidad de inscripción: '>
                    <Input onChange={onChangeAmount} value={amount}></Input>
                </Form.Item>
                <Form.Item label='Metodo de pago: '>
                    <Radio.Group onChange={onChangeType} value={type} name="type">
                        <Radio value={'Efectivo'}>Efectivo</Radio>
                        <Radio value={'Oxxo'}>Oxxo</Radio>
                        <Radio value={'Tarjeta/SPEI'}>Tarjeta/SPEI</Radio>
                    </Radio.Group>
                </Form.Item>
            </Modal>
        </div>
    )
}

export default ApplicantList;