import React, { useEffect, useState, useRef } from 'react';
import { ResponseResult, Scholarship, RouteParam } from '../../types';
import {Card, Row, Col, Button, Form, Input, Select, Checkbox} from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function ScholarshipEdit(){

    const { Option } = Select;
    const { id } = useParams<RouteParam>();
    const [scholarship, setScholarship] = useState<Partial<Scholarship>>({});
    const form:any = useRef(null);

    useEffect(()=>{
        getScholarship();
    }, []);

    useEffect(() => {
        form.current.resetFields()
    }, [scholarship])

    const getScholarship = () => {
        Swal.fire("Editar beca", "Cargando...");
        Swal.showLoading();
        request("/api/scholarship/"+id,{
            method:'GET',
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                setScholarship(result.data);
            }else{
                Swal.fire("Editar beca", "Ocurrió un error al cargar la beca", "error");
            }
        })
    }

    const submit = (values: Scholarship) => {
        Swal.fire("Editar beca", "Guardando beca...");
        Swal.showLoading();
        request("/api/scholarship/"+id,{
            method:'PUT',
            body: JSON.stringify(values)
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                window.location.href = "/scholarships";
            }else{
                Swal.fire("Editar beca", "Ocurrió un error al guardar la beca", "error");
            }
        })
    }

    return (
        <div>
            <Row>
                <Col xs={12} md={{span:8,offset:8}}>
                    <Card>
                        <h2>Editar beca</h2>
                        <Form onFinish={submit} initialValues={scholarship} ref={form}>
                            <Form.Item label="Nombre de la beca" name="name" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa el nombre de la beca',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Tipo" name="type" rules={[
                                        {
                                            required: true,
                                            message: 'Selecciona el tipo',
                                        },
                                    ]}>
                                <Select>
                                    <Option>Seleccionar tipo</Option>
                                    <Option value="porcentaje">Porcentaje</Option>
                                    <Option value="cantidad">Cantidad</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="Cantidad a descontar" name="amount" rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa la cantidad a descontar',
                                        },
                                    ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item className="mb-3" name="ignore_surcharges" valuePropName="checked">
                                <Checkbox id="ignore_surcharges">
                                    ¿Ignorar recargos?
                                </Checkbox>
                            </Form.Item> 
                            <Button type="primary" htmlType="submit">
                               Guardar
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )

}

export default ScholarshipEdit;