import React, {useState} from 'react';
import {Card, Row, Col, Button, Form, Select} from 'antd';
import { ResponseResult } from '../../types';
import { request } from '../../services/Request';
import {useDropzone} from 'react-dropzone';
import Swal from 'sweetalert2';

function StudentEnableConcepts(){

    const [selectedFiles, setFiles]:any = useState([]);
    const { Option } = Select;
    const [option, setOption] = useState('');

    const onDrop = (acceptedFiles:any) => {
        let files = selectedFiles;
        files = acceptedFiles;
        setFiles(files);
    };
    
    const {getRootProps, getInputProps } = useDropzone({
        multiple:false, 
        onDrop: onDrop,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    });

    const upload = () => {
        if(option === ''){
            Swal.fire(`Habilitar/deshabilitar conceptos`, `Es necesario escoger si se van a habilitar o deshabilitar los conceptos`, 'error');
        }else{
            if(selectedFiles.length > 0){
                Swal.fire(`${option === 'true' ? 'Habilitar' : 'Deshabilitar'}`,'Cargando...');
                Swal.showLoading();
                const data = new FormData();
                for(let file of selectedFiles){
                    data.append('files', file);
                }
                data.append('option', option);
                request('/api/student/enable',{
                    method: 'POST',
                    file:true,
                    body: data
                })
                .then((result:ResponseResult)=>{
                    Swal.close();                
                    if(result.data){
                        Swal.fire(`${option === 'true' ? 'Habilitar' : 'Deshabilitar'}`, 'El proceso terminó correctamente', 'success');
                    }else{
                        Swal.fire(`${option === 'true' ? 'Habilitar' : 'Deshabilitar'}`, 'Ocurrió un error', 'error');
                    }
                })
            }
        }
    }

    return (
        <div id="upload-students">
            <Row>
                <Col xs={24} md={{span:18,offset:3}}>
                    <Card>
                        <h3>Habilitar/deshabilitar conceptos de alumnos</h3>                        
                            <a href="/plantilla_alumnos_conceptos.xlsx" className="mb-3">Descargar plantilla</a>
                            <div className='file-drop justify-center mt-5' {...getRootProps()}>
                                <input {...getInputProps({multiple:false})} />
                                <p className="text-center mb-4">Arrastra y suelta tus archivo o presiona aquí para elegirlo.</p> 
                            </div>
                            <p><b>Archivo seleccionado:</b></p>
                            <ul className="mb-5">
                                {
                                    selectedFiles.map((file:any,key:number)=>(
                                        <li key={key}>
                                            { file.name }
                                        </li>
                                    ))
                                }
                            </ul>
                            <Form.Item label="Selecciona una opción" name="type">
                                <Select className="type" onChange={(e:string) => setOption(e)}>
                                    <Option>Selecciona una opción</Option>
                                    <Option value="true">Habilitar</Option>
                                    <Option value="false">Deshabilitar</Option>
                                </Select>
                            </Form.Item>
                            <Button type="primary" onClick={upload}>Subir archivo</Button>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default StudentEnableConcepts;